.hpr-services-list {
	margin-bottom: 30px;

	li {
		@include text(kappa, 18);
		align-items: center;
		background-color: $color-white;
		color: $color-black;
		display: flex;
		font-family: $font-family-primary;
		justify-content: space-between;
		padding: 10px;

		&:nth-of-type(even) {
			background-color: $color-gray-light;
		}
	}

	.item-link {
		color: $color-blue-light;
		margin-left: 2rem;
		flex: 0 0 auto;
	}

	@include breakpoint(md) {
		columns: 2;
		column-gap: 24px;
		margin-bottom: 40px;
	}
}

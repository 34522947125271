.hpr-app-banner {
	z-index: 20;
	width: 100%;
	padding: 0.8rem;
	background-color: $color-gray-light;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;

	&.hidden {
		display: none;
	}

	.app-banner-content {
		flex: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.5rem;

		.app-banner-text {
			display: flex;
			flex-direction: column;
			gap: 0.25rem;

			h4 {
				font-size: 1rem;
			}

			p {
				font-size: 0.813rem;
				color: $color-gray-dark;
			}
		}
	}

	.hpr-button {
		&.hidden {
			display: none;
		}
	}

	@include breakpoint(1024px) {
		display: none;
	}
}

.hpr-patient-cards-list {
	color: $color-black;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.patient-card {
		&:not(:last-of-type) {
			padding-bottom: 29px;
			border-bottom: 1px solid $color-neutral-light;
		}
	}

	@include breakpoint(md) {
		align-items: stretch;
		flex-direction: row;
		flex-wrap: wrap;

		.patient-card {
			flex: 0 1 50%;

			&:not(:last-of-type) {
				padding-bottom: 0;
				border-bottom: none;
			}

			&:nth-of-type(odd) {
				padding-right: 12px;
				border-right: 1px solid $color-neutral-light;
			}

			&:nth-of-type(even) {
				padding-left: 12px;
			}

			&:nth-last-of-type(2),
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

.hpr-image-top-cta {
	background-color: $color-gray-light;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 352px;
	border-radius: 6px;

	.image-container {
		height: 0;
		width: 100%;
		padding-top: calc(220 / 352 * 100%);
		position: relative;

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			aspect-ratio: 352 / 220;
			border-radius: 6px 6px 0px 0px;
			object-fit: cover;
		}
	}

	.content {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0.375rem;
		padding: 1.25rem;

		.hpr-text-truncated {
			max-height: 4rem;
			-webkit-line-clamp: 3;
		}

		a {
			margin-top: 0.563rem;
		}
	}
}

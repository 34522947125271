.hpr-full-background-cta {
	width: 100%;
	position: relative;

	.background-image {
		position: relative;

		.background-image-wrapper {
			position: relative;
			height: 0;
			width: 100%;
			padding-top: calc(13 / 18 * 100%);

			.background-image-picture {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				aspect-ratio: 18 / 13;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}

	.full-background-container {
		@include make-container();
		margin-top: 20px;
		margin-bottom: 50px;

		.container-inner {
			display: flex;
			flex-direction: column;
			gap: 0.75rem;

			.full-background-cta-title,
			h2 {
				@include text(32);
				text-align: left;
			}

			p {
				@include text(18, 24);
			}

			.hpr-button {
				margin-top: 6px;
			}
		}
	}

	@include breakpoint(md) {
		height: 0;
		width: 100%;
		position: relative;
		padding-top: calc(450 / 1281 * 100%);

		&.white-text {
			h2,
			p {
				color: $color-white;
			}
		}

		.background-image {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			.background-image-wrapper {
				padding-top: calc(450 / 1281 * 100%);

				.background-image-picture {
					aspect-ratio: 1281 / 450;
				}
			}
		}

		.full-background-container {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
			width: 100%;
			display: flex;
			justify-content: flex-end;

			.container-inner {
				width: 50%;
				align-self: flex-end;

				.hpr-button {
					max-width: 325px;
				}
			}
		}
	}

	@include breakpoint(870px) {
		padding-top: calc(338 / 1281 * 100%);

		.background-image {
			.background-image-wrapper {
				padding-top: calc(338 / 1281 * 100%);
				.background-image-picture {
					aspect-ratio: 1281 / 338;
				}
			}
		}
	}
}

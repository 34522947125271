.hpr-urgency-banner {
	@include text(lambda, 16);
	background-color: $color-warning;
	color: $color-black;
	font-family: $font-family-primary;
	font-weight: $font-weight-bold;
	padding: 11px 32px 16px;
	text-align: center;
	text-transform: uppercase;

	p:last-of-type {
		margin-bottom: 0;
	}

	.urgency-banner-content {
		background-color: $color-warning;
		border-radius: 0;
		margin-bottom: 0;
		padding: 0;

		a {
			@include text(lambda, 16);
			color: $color-blue-primary;
		}

		h2 {
			@include text(lambda, 16);
			color: $color-black;

			a {
				color: $color-blue-primary;
			}
		}
	}

	@include breakpoint(md) {
		padding: 9px 0;

		.urgency-banner-content {
			@include make-container();
		}
	}
}

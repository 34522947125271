.layout-two-column {
	background-color: $color-neutral;

	@include breakpoint(lg) {
		.layout-row {
			@include make-row();
			justify-content: space-between;
		}

		.column-main {
			@include make-col-ready();
			@include make-col(8);
		}

		.column-sidebar {
			@include make-col-ready();
			@include make-col(3);
		}
	}
}

.hpr-primary-cards-list {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3.5rem;

	@include breakpoint(md) {
		align-items: start;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 24px;

		.hpr-image-card {
			height: 100%;

			.card-container {
				flex: 1;
			}

			.hpr-button {
				display: block;
				width: 100%;
			}

			.image-card-info {
				.hpr-button {
					display: none;
				}
			}

			.image-wrapper {
				flex: 0 1 50%;
				width: auto;
			}
		}
	}

	@include breakpoint(1100px) {
		.hpr-image-card {
			.image-card-info {
				.hpr-button {
					display: block;
					width: auto;
				}
			}

			.hpr-button {
				display: none;
			}
		}
	}
}

.hpr-desktop-navigation {
	width: 100%;
	display: none;

	.hpr-utility-nav {
		width: 100%;
		background-color: $color-gray-light-secondary;

		.utility-nav-inner {
			@include make-container();
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding-top: 0.4rem;
			padding-bottom: 0.4rem;
			gap: 4.5rem;

			nav {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				gap: 3.5rem;
				width: 100%;
				max-width: 670px;

				a {
					@include text(16, 28.8);
					letter-spacing: 1.6px;
					text-transform: uppercase;
					color: $color-blue-dark;
					font-family: $font-family-tertiary;
					font-weight: 500;
					white-space: nowrap;
				}
			}

			.actions {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				gap: 4.5rem;
				flex: 1;

				.hpr-button-link {
					color: $color-white;
					background-color: $color-blue-tertiary;
					font-family: $font-family-tertiary;
					font-weight: 500;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					gap: 0.625rem;
					white-space: nowrap;
				}

				.hpr-geolocate-user .input-with-icon {
					flex-direction: row-reverse;
				}
			}
		}
	}

	.hpr-main-nav {
		width: 100%;
		position: relative;
		background-color: $color-blue-dark;
		padding: 1.5rem 0;

		* {
			color: $color-white;
		}

		.main-nav-inner {
			@include make-container();
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			h1 {
				margin: 0 !important;
				padding: 0 !important;
			}

			nav {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 3rem;

				.nav-item-wrapper {
					@include text(24);

					.nav-item {
						@include text(24);
						cursor: pointer;
						margin: 0;
						padding: 0;
						font-family: $font-family-tertiary;
						font-weight: 400;
						text-transform: none;

						.nav-item-title {
							pointer-events: none;
						}

						.nav-item-icon {
							margin-left: 0.375rem;

							&.open {
								transform: rotate(180deg);
							}
						}

						&:hover {
							background-color: transparent;
						}
					}
				}

				.hpr-search-icon {
					cursor: pointer;
					text-decoration: none;
					display: block;
					width: 32px;
					height: 32px;

					svg {
						width: 32px;
						height: 32px;
						display: none;
						pointer-events: none;

						&.hpr-show {
							display: block;
						}
					}
				}
			}

			.nav-dropdown {
				display: none;
				position: absolute;
				opacity: 0;
				left: 0;
				top: calc(100%);
				width: 100vw;
				background-color: $color-white;
				transition: opacity 0.3s ease-in-out;
				box-shadow: 0px 15px 20px 0px rgba(0, 60, 114, 0.1);
				z-index: 10;

				&.show {
					display: block;
				}

				&.visible {
					opacity: 1;
				}

				.nav-dropdown-inner {
					@include make-container();
					padding-top: 1.5rem;
					padding-bottom: 1.5rem;
					display: flex;
					flex-direction: column;
					gap: 1rem;

					h2 {
						@include text(32, 38);
						font-family: $font-family-tertiary;
						font-weight: 400;
						color: $color-blue-dark;
					}

					.inner-dropdown-navigation {
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						gap: 2.75rem;

						> .hpr-button {
							background-color: $color-blue-tertiary;
							font-family: $font-family-tertiary;
							font-weight: 300;
							display: flex;
							align-items: center;
							gap: 8px;
							white-space: nowrap;
						}

						ul {
							list-style: none;
							margin: 0;
							padding: 0;
							width: 100%;
							display: grid;
							padding-top: 0.25rem;
							padding-bottom: 1.5rem;
							grid-template-columns: repeat(5, 1fr);
							grid-column-gap: 2.75rem;
							grid-row-gap: 1rem;

							li {
								padding: 0;

								&:nth-child(odd) {
									order: 1;
								}

								&:nth-child(even) {
									order: 2;
								}

								a {
									@include text(16, 16);
									cursor: pointer;
									font-family: $font-family-tertiary;
									font-weight: 400;
									color: $color-blue-tertiary;
									text-decoration: underline;
									transition: all 0.3s ease;

									&:hover {
										filter: brightness(1.4);
									}
								}
							}
						}
					}

					.nav-columns {
						display: flex;
						flex-direction: row;
						align-items: stretch;
						justify-content: flex-start;
						flex-wrap: wrap;
						gap: 1rem;
						padding-bottom: 1rem;

						.nav-column {
							display: flex;
							flex-direction: column;
							gap: 1.5rem;

							.nav-column-items {
								display: grid;
								grid-template-columns: repeat(2, 1fr);
								grid-column-gap: 2rem;
								grid-row-gap: 1.5rem;

								a {
									@include text(16, 16);
									cursor: pointer;
									font-family: $font-family-tertiary;
									font-weight: 400;
									color: $color-blue-tertiary;
									text-decoration: underline;
									transition: all 0.3s ease;
									text-transform: none;

									&:hover {
										filter: brightness(1.4);
									}

									&.hpr-button {
										background-color: $color-blue-tertiary;
										font-family: $font-family-tertiary;
										text-decoration: none;
										color: $color-white;
										font-weight: 300;
										display: flex;
										align-items: center;
										gap: 8px;
										white-space: nowrap;
									}
								}
							}
						}

						.nav-column-divider {
							width: 1px;
							background: $color-yellow;
							margin: 0 2rem;
						}
					}
				}
			}
		}
	}

	@include breakpoint(lg) {
		display: block;
	}
}

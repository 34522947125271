.hpr-social-link {
	padding: 0 10px;

	&:last-child() {
		padding-right: 0;
	}

	.hpr-icon {
		height: 35px;
		width: 35px;
	}

	&:hover {
		.hpr-icon {
			display: none;
		}
		.hpr-icon[class$='-hover'] {
			display: block;
		}
	}

	.hpr-icon[class$='-hover'] {
		display: none;
	}
}

.hpr-footer {
	padding: 2rem 0;
	background-color: $color-blue-dark;

	* {
		color: $color-white;
		font-family: $font-family-tertiary;
	}

	ul {
		display: block;
		position: relative;
		top: 0;
	}

	.footer-inner {
		@include make-container();
		display: flex;
		flex-direction: column;
		gap: 2rem;

		.footer-logo {
			width: 100%;
			max-width: 216px;
			align-self: center;
		}

		.footer-nav {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			height: auto;
			text-transform: none;
			float: none;

			.footer-nav-item {
				padding: 15px 22px;
				border: 1px solid $color-yellow;
				display: flex;
				flex-direction: column;
				gap: 1rem;

				.nav-item-title {
					@include text(18, 23.4);
					font-weight: 400;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					gap: 1rem;
					cursor: pointer;
					font-family: $font-family-tertiary;
					text-transform: none;

					.hpr-expand-icon {
						display: block;
						pointer-events: none;
						width: 30px;
						height: 30px;

						.hpr-icon {
							width: 30px;
							height: 30px;
						}
					}
				}

				&.footer-item-expanded {
					.nav-item-list {
						display: flex;
					}
				}

				.nav-item-list {
					display: none;
					flex-direction: column;
					gap: 0.5rem;
					padding: 0;
					list-style: none;

					li a {
						@include text(16, 30);
						font-weight: 300;
						width: 100%;
						display: block;
						padding: 0;
					}

					.hpr-separator {
						margin: 0;
						background-color: $color-yellow;
						opacity: 0.5;
					}
				}
			}
		}

		.footer-connect {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1rem;

			h3 {
				@include text(20, 26);
				color: $color-white;
				font-weight: $font-weight-bold;
				margin: 0;
			}

			.hpr-footer-contact {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1.5rem;

				a {
					@include text(14, 21);
					display: flex;
					align-items: center;
					gap: 0.625rem;
				}
			}

			.footer-social {
				display: flex;
				flex-direction: row;
				align-items: center;
				flex-wrap: wrap;
				gap: 1rem;

				a {
					display: flex;
					align-items: center;
					justify-content: center;
					text-decoration: none;
					width: 40px;
					height: 40px;
					border: 2px solid $color-blue-light;
					border-radius: 50%;
					transition: border 0.3s ease;

					&:hover {
						border: 2px solid $color-white;
					}

					.hpr-icon {
						height: 55%;
						width: 55%;
					}
				}
			}

			.footer-apps {
				display: flex;
				flex-direction: column;
				gap: 1rem;

				p {
					@include text(16, 20.8);
					font-weight: 400;
				}

				.footer-apps-links {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 1rem;

					a {
						display: block;
						height: 38px;

						img {
							height: 100%;
							object-fit: contain;
						}
					}
				}
			}
		}

		.footer-legal {
			width: 100%;
			border-top: 1px solid $color-white;

			.footer-legal-links {
				margin: 1.5rem 0;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				gap: 1rem;

				a {
					@include text(16, 24);
					font-weight: 300;
				}
			}

			.copyright {
				display: flex;
				flex-direction: column;
				opacity: 0.6;

				p {
					@include text(14, 25.2);
					font-weight: 400;
				}
			}
		}
	}

	@include breakpoint(md) {
		padding: 3.5rem 0 3rem;

		* {
			font-family: $font-family-primary;
		}

		.footer-inner {
			gap: 2.5rem;

			.footer-logo {
				max-width: 254px;
				align-self: flex-start;
			}

			.footer-nav {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-column-gap: 2.875rem;

				.footer-nav-item {
					border: none;
					padding: 0;
					gap: 21.84px;

					.nav-item-title {
						@include text(20, 26);
						font-weight: $font-weight-bold;
						width: fit-content;
						pointer-events: none;
						text-align: left;

						.hpr-expand-icon {
							display: none;
						}
					}

					.nav-item-list {
						display: flex;
						gap: 1rem;

						li a {
							@include text(14, 16);
							font-weight: 200;
						}

						.hpr-separator {
							display: none;
						}
					}
				}
			}

			.footer-connect {
				gap: 1.125rem;

				h3 {
					@include text(20, 26);
					font-weight: $font-weight-bold;
					width: fit-content;
					pointer-events: none;
					text-align: left;
				}

				.hpr-footer-contact {
					a {
						font-weight: 200;
					}
				}

				.footer-social {
					margin-top: 0.5rem;
					gap: 0.625rem;

					a {
						width: 35px;
						height: 35px;
						border-width: 1px;

						&:hover {
							border-width: 1px;
						}
					}
				}

				.footer-apps {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 2.25rem;

					p {
						@include text(16, 20.8);
						font-weight: 300;
					}
				}
			}

			.footer-legal {
				border-top: 1px solid rgba(23, 127, 180, 0.5);

				.footer-legal-links {
					margin: 2rem 0 1.25rem;
					gap: 2rem;

					a {
						@include text(14, 16);
						font-weight: $font-weight-bold;
					}
				}

				.copyright {
					flex-direction: row;
					gap: 4px;

					p {
						@include text(12, 14);
						font-weight: $font-weight-regular;
					}
				}
			}
		}
	}
}

.hpr-layout-full-width {
	background-color: $color-white;
	margin: 0;
	max-width: none;

	main,
	.hpr-containerized-element {
		@include make-container();
		padding-bottom: 2rem;
		padding-top: 2rem;
	}

	.hpr-gray-bg {
		background-color: $color-gray-light;
	}

	@include breakpoint(md) {
		main,
		.hpr-containerized-element {
			padding-bottom: 50px;
			padding-top: 50px;
		}
	}
}

.hpr-global-navigation {
	top: 0;
	width: 100%;
	position: sticky;
	height: 52px;
	z-index: 1000;

	.hpr-search-bar-container {
		background: $color-white;
	}

	@include breakpoint(lg) {
		height: 139px;
	}
}

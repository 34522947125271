button.hpr-hamburger-button {
	background: none;
	border: 0;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 33px; // Adjusted width
	height: 36px; // Adjusted height
	position: relative;

	.hamburger-line {
		background-color: $color-white;
		height: 3px;
		width: 100%;
		transition: all 0.1s ease-in-out;
		border-radius: 4px;
	}

	&.open {
		.hamburger-line {
			&:nth-child(1) {
				transform: translateY(12px) rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translateY(-12px) rotate(-45deg);
			}
		}
	}
}

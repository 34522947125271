.hpr-cta-one {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.875rem;
	width: 100%;

	p {
		width: 100%;
		text-align: center;
	}

	a {
		width: 100%;
		max-width: 245px;
	}

	@include breakpoint(sm) {
		max-width: 325px;
	}
}

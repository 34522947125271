.hpr-star {
	display: inline-block;
	position: relative;
	height: 21px;
	width: 22px;

	&:last-child {
		margin-right: 0;
	}

	svg {
		fill: $color-neutral;
		height: 21px;
		width: 22px;
		position: absolute;
		top: 0;
		left: 0;
	}

	.star-fill {
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		width: 0;
		height: 21px;

		svg {
			fill: $color-gold;
		}
	}
}

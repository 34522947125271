$default-icon-color: $color-black;

.hpr-icon {
	height: 1rem;
	stroke-linecap: round;
	width: 1rem;

	use {
		path {
			all: inherit;
		}
	}
}

/*
 *
 * TYPOGRAPHY VARIABLES AND MAPS
 *
 */

/*
  * Font Size and Line Height Maps
  * Matching keys gets used together by default
  * Used in text() mixin and calulateLineHeight() function
  */

$font-size-scale: (
	alpha: 54,
	beta: 48,
	gamma: 37,
	delta: 30,
	epsilon: 26,
	zeta: 24,
	eta: 22,
	theta: 20,
	iota: 18,
	kappa: 16,
	lambda: 14,
	mu: 12,
);

$line-height-scale: (
	alpha: 54,
	beta: 48,
	gamma: 37,
	delta: 30,
	epsilon: 32,
	zeta: 32,
	eta: 32,
	pie: 36,
	theta: 30,
	iota: 24,
	kappa: 22,
	lambda: 20,
	mu: 18,
);

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicBold.woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicBoldItalic.woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicBoldItalicSub.woff');
	font-weight: bold;
	font-style: italic;
	font-feature-settings: 'subs';
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicBoldSub.woff');
	font-weight: bold;
	font-style: normal;
	font-feature-settings: 'subs';
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicExtraLight.woff');
	font-weight: 200; /* Extra Light */
	font-style: normal;
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicExtraLightItalic.woff');
	font-weight: 200; /* Extra Light */
	font-style: italic;
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicExtraLightItalicSub.woff');
	font-weight: 200; /* Extra Light */
	font-style: italic;
	font-feature-settings: 'subs';
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicExtraLightSub.woff');
	font-weight: 200; /* Extra Light */
	font-style: normal;
	font-feature-settings: 'subs';
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicLight.woff');
	font-weight: 300; /* Light */
	font-style: normal;
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicLightItalic.woff');
	font-weight: 300; /* Light */
	font-style: italic;
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicLightItalicSub.woff');
	font-weight: 300; /* Light */
	font-style: italic;
	font-feature-settings: 'subs';
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicLightSub.woff');
	font-weight: 300; /* Light */
	font-style: normal;
	font-feature-settings: 'subs';
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicMedium.woff');
	font-weight: 500; /* Medium */
	font-style: normal;
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicMediumItalic.woff');
	font-weight: 500; /* Medium */
	font-style: italic;
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicMediumItalicSub.woff');
	font-weight: 500; /* Medium */
	font-style: italic;
	font-feature-settings: 'subs';
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicMediumSub.woff');
	font-weight: 500; /* Medium */
	font-style: normal;
	font-feature-settings: 'subs';
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicRegular.woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicRegularItalic.woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicRegularItalicSub.woff');
	font-weight: normal;
	font-style: italic;
	font-feature-settings: 'subs';
}

@font-face {
	font-family: 'Seravek';
	src: url('/assets/fonts/seravek/SeravekWebBasicRegularSub.woff');
	font-weight: normal;
	font-style: normal;
	font-feature-settings: 'subs';
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-Black.otf') format('opentype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-BlackItalic.otf')
		format('opentype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-Bold.otf') format('opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-BoldItalic.otf')
		format('opentype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-Book.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-BookItalic.otf')
		format('opentype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-Extlight.otf')
		format('opentype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-ExtlightItalic.otf')
		format('opentype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-Extrabold.otf')
		format('opentype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-ExtraboldItalic.otf')
		format('opentype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-Italic.otf') format('opentype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-LightItalic.otf')
		format('opentype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-MediumItalic.otf')
		format('opentype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-Thin.otf') format('opentype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT-ThinItalic.otf')
		format('opentype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Kievit Slab OT';
	src: url('/assets/fonts/kievit/KievitSlabOT.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

$font-family-primary: 'Seravek', sans-serif;
$font-family-secondary: 'Open Sans', sans-serif;
$font-family-tertiary: 'Seravek', sans-serif;
$font-family-quaternary: 'Kievit Slab OT', serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;

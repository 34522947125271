@media print {
	body,
	html {
		width: 1100px;
	}

	* {
		transition: none !important;
	}

	header,
	footer,
	aside,
	nav,
	form,
	iframe {
		display: none !important;
	}

	@page {
		size: 330mm 427mm;
		margin: 14mm;
	}
}

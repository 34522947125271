button[class^='hpr-'],
button[class*='hpr-'],
[class*='hpr-'] button,
.hpr-uci-app button {
	background: transparent;
	border: 0;
	border-radius: 0;
	margin: 0;
	text-transform: none;
	height: auto;
	padding: 0 !important;
}

[class*='hpr-'] .hpr-button,
.hpr-uci-app .hpr-button,
.hpr-button {
	color: $color-white;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	font-family: $font-family-primary;
	margin: 0;
	padding: 0.938rem 1rem !important;
	border-radius: 0.25rem;
	background: $color-blue-primary;
	transition: all 0.3s ease;

	// Text Styles
	@include text(16);
	font-weight: 600;
	text-decoration: none;

	&:hover,
	&:active {
		filter: brightness(1.2);
	}

	&.button-secondary {
		background-color: transparent;
		border: 1px solid $color-blue-primary;
		color: $color-blue-primary;

		&:hover,
		&:active {
			background-color: $color-blue-primary;
			color: $color-white;
			filter: brightness(1.2);
		}
	}

	&.button-tertiary {
		background-color: transparent;
		border: 1px solid $color-black;
		color: $color-black;

		&:hover,
		&:active {
			background-color: $color-white;
		}
	}

	&.button-outline {
		background-color: transparent;
		border: 1px solid $color-blue-primary;
		color: $color-blue-primary;

		&:hover,
		&:active {
			background-color: $color-blue-primary;
			color: $color-white;
		}
	}

	&.button-outline-white {
		background-color: transparent;
		border: 1px solid $color-white;
		color: $color-white;

		&:hover,
		&:active {
			background-color: $color-white;
			color: $color-black;
		}
	}

	&.disabled {
		background-color: $color-neutral;
		border: 1px solid $color-neutral;
		color: $color-black;
		cursor: default;

		&:hover,
		&:active {
			cursor: default;
		}
	}

	@include breakpoint(lg) {
		min-width: 259px;
	}
}

.hpr-zipcode-popover {
	position: relative;

	.popover-trigger {
		display: flex;
		cursor: pointer;

		.hpr-popover-trigger-btn {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 0.3rem;
			margin: 0;
			cursor: pointer;

			.icon-location {
				fill: $color-blue-light;
			}

			.zip-value {
				@include text(kappa);
				font-family: $font-family-secondary;
				font-weight: 700;
				color: $color-blue-dark;
			}
		}
	}

	.popover-content {
		background: $color-white;
		position: absolute;
		z-index: 50;
		width: 258px;
		bottom: 225%;
		left: 0;
		border-radius: 6px;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		padding: 1rem;
		border: 1px solid #fff;
		height: fit-content;

		display: none;
		flex-direction: column;
		gap: 10px;

		&.show {
			display: flex;
		}

		&::before {
			content: '';
			position: absolute;
			bottom: -20px;
			left: 2rem;
			margin-left: -10px;
			border-width: 10px;
			border-style: solid;
			border-color: $color-white transparent transparent transparent;
			z-index: 49;
		}

		.hpr-popover-close-btn {
			position: absolute;
			right: 1rem;
			top: 0.8rem;
			cursor: pointer;
			margin: 0;
		}

		.hpr-geolocate-user {
			width: 100%;

			.input-with-icon {
				width: 100%;

				.zip-input {
					width: 100%;
					border: 1px solid $color-neutral-light;
					padding: 12px 14px;
					border-radius: 6px;
					font-family: $font-family-primary;
					font-weight: 400;
				}
			}
		}

		p {
			font-size: 14px;
		}

		.popover-update-btn {
			min-width: 0;
		}
	}

	@include breakpoint(lg) {
		.popover-content {
			top: 225%;
			right: -2rem;
			left: initial;

			&::before {
				content: '';
				position: absolute;
				top: -20px;
				right: 2rem;
				left: initial;
				bottom: initial;
				margin-left: -10px;
				border-width: 10px;
				border-style: solid;
				border-color: transparent transparent $color-white transparent;
				z-index: 49;
			}
		}
	}
}

[class^='hpr-'] {
	h1 {
		@include text(alpha);
		color: $color-black;
		font-family: $font-family-primary;
		font-weight: $font-weight-bold;
		margin-top: 0;
	}

	h2 {
		@include text(32);
		color: $color-black;
		font-family: $font-family-primary;
		font-weight: $font-weight-bold;
		margin-top: 0;
	}

	h3 {
		@include text(24);
		color: $color-black;
		font-family: $font-family-primary;
		font-weight: $font-weight-bold;
		margin-top: 0;
	}

	h4 {
		@include text(18);
		color: $color-black;
		font-family: $font-family-primary;
		font-weight: $font-weight-bold;
		margin-top: 0;
	}
}

.hpr-page-title {
	@include text(32, 36);
	margin-bottom: 26px;

	@include breakpoint(md) {
		margin-bottom: 50px;
	}
}

.hpr-section-title {
	@include text(iota, 21);
	font-family: $font-family-primary;
	font-weight: $font-weight-bold;
	margin: 0 auto 30px;
	max-width: 800px;

	+ .hpr-section-title {
		margin-top: -20px;
	}

	a {
		color: $color-blue-light;
	}

	@include breakpoint(md) {
		@include text(zeta, 28);
		margin-bottom: 60px;

		+ .hpr-section-title {
			margin-top: -50px;
		}
	}
}

.hpr-divider-title {
	align-items: center;
	display: flex;
	margin-bottom: 30px;
	text-align: center;
	flex: 1;
	gap: 6px;

	&:before,
	&:after {
		content: '';
		flex-grow: 1;
		margin: 0px 4px;
		height: 1px;
		background: $color-neutral-light;
	}

	@include breakpoint(md) {
		margin-bottom: 40px;
	}
}

.hpr-text-center {
	text-align: center;
}

.layout-wrapper {
	@include make-container();
	padding-top: 2rem;

	section {
		margin-bottom: 2rem;
	}

	.column-main,
	.column-sidebar {
		padding-bottom: 3rem;
	}

	.column-sidebar {
		display: flex;
		flex-wrap: wrap;

		> section {
			margin-bottom: 1.5625rem;
			margin-right: 1.5625rem;
		}
	}

	@include breakpoint(lg) {
		section {
			margin-bottom: 3.125rem;
		}

		.column-sidebar {
			display: flex;
			flex-direction: column;
			flex-wrap: no-wrap;

			> section {
				margin-right: 0;
			}
		}
	}
}

.hpr-uci-app {
	-webkit-font-smoothing: auto;

	> section {
		margin-bottom: 2rem;
		margin: 0 auto;
	}

	@include breakpoint(md) {
		> section {
			margin-bottom: 3.125rem;
		}
	}
}

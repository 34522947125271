.hpr-button-link {
	@include text(16, 16);
	cursor: pointer;
	text-decoration: none;
	transition: filter 0.3s ease;
	padding: 0.7rem 1.5rem;
	font-family: $font-family-primary;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1.6px;
	border-radius: 0.625rem;

	&:hover {
		filter: brightness(1.1);
	}
}

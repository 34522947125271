.hpr-mobile-navigation {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: relative;
	z-index: 10;

	ul {
		position: relative;
		top: 0;
	}

	.nav-header {
		@include make-container();
		height: 52px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		background-color: $color-blue-dark;

		.hpr-logo {
			max-width: 162px;

			h1 {
				margin: 0 !important;
				padding: 0 !important;
			}
		}

		.header-controls {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1rem;

			.hpr-search-icon {
				width: 30px;
				height: 30px;

				svg {
					width: 30px;
					height: 30px;
					display: none;
					pointer-events: none;

					&.hpr-show {
						display: block;
					}
				}
			}
		}
	}

	.mobile-nav-menu {
		display: none;
		flex-direction: column;
		justify-content: space-between;
		height: calc(100vh - 52px);
		overflow-y: scroll;
		background-color: $color-white-secondary;
		opacity: 0;
		padding-top: 50px;
		width: 100%;
		position: absolute;
		overflow-x: hidden;
		top: 100%;
		z-index: 8;

		&.app-banner-active {
			height: calc(100vh - 52px - 88px);
		}

		&.show {
			display: flex;
		}

		&.visible {
			opacity: 1;
		}

		.nav-container {
			@include make-container();
			display: flex;
			height: 100%;
			flex-direction: column;

			.mobile-main-nav {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 1.5rem;
				z-index: 1;
				top: 0;
				position: static;

				* {
					color: $color-blue-light;
				}

				.nav-item-wrapper {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.nav-item {
						cursor: pointer;
						margin: 0;
						padding: 0;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;
						width: 100%;
						text-align: left;
						color: $color-blue-tertiary;
						font-family: $font-family-tertiary;
						font-weight: 400;

						.nav-item-icon-mobile {
							margin-left: 0.375rem;
							width: 14px;
							pointer-events: none;
						}

						&:hover {
							background-color: transparent;
						}
					}

					.nav-item-title {
						@include text(20);
						color: $color-blue-tertiary;
						font-family: $font-family-tertiary;
						font-weight: 400;
						padding: 0;
						text-decoration: underline;
						text-transform: none;
					}

					a.nav-item-title {
						width: 100%;
						text-align: left;
						display: block;
						text-transform: none;
					}

					.nav-slider {
						display: flex;
						background: $color-white;
						position: absolute;
						top: 0;
						left: 100%;
						width: 100%;
						height: 100%;
						transition: all 0.3s ease-in-out;
						padding: 1.5rem;
						flex-direction: column;
						gap: 1rem;
						opacity: 0;

						&.show {
							left: 0;
							opacity: 1;
						}

						.nav-slider-back {
							display: flex;
							flex-direction: row;
							align-items: center;
							align-self: flex-end;
							gap: 5px;
							cursor: pointer;
							color: $color-blue-tertiary;
							font-family: $font-family-tertiary;
							font-weight: 400;
							text-decoration: none;
							transition: filter 0.3s ease;
							line-height: 1rem;

							&:hover,
							&:active {
								filter: brightness(1.1);
							}
						}

						.nav-slider-content {
							display: flex;
							flex-direction: column;
							gap: 1rem;

							h3 {
								color: $color-blue-dark;
								font-family: $font-family-tertiary;
								font-weight: 400;
							}

							.hpr-button {
								background-color: $color-blue-tertiary;
								font-family: $font-family-tertiary;
								color: $color-white;
								font-weight: 300;
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 5px;
								margin-bottom: 0.5rem;
							}

							ul {
								margin: 0;
								padding: 0;
								display: grid;
								grid-template-columns: repeat(2, 1fr);
								list-style: none;
								gap: 1.5rem;

								li {
									padding: 0;

									a {
										line-height: 1.5rem;
										display: flex;
										flex-direction: row;
										align-items: center;
										gap: 8px;
									}
								}
							}

							.hpr-separator {
								display: block;
								margin: 1rem 0;
							}
						}
					}
				}
			}

			.hpr-separator {
				display: block;
				background-color: $color-yellow;
				margin: 1.5rem 0;
			}

			.mobile-utility-nav {
				display: flex;
				flex-direction: column;
				gap: 1.5rem;
				z-index: 0;
				top: initial;
				bottom: initial;

				.hpr-text-link {
					@include text(20);
					color: $color-blue-tertiary;
					font-family: $font-family-tertiary;
					font-weight: 400;
					text-decoration: underline;
				}

				.my-chart-mobile {
					@include text(14);
					color: $color-white;
					background-color: $color-blue-primary;
					border-radius: 0.25rem;
					padding: 1rem;
					width: 100%;
					max-width: 184px;
					text-align: center;
					text-decoration: none;
					margin-top: 0.5rem;
					transition: filter 0.3s ease;

					&:hover,
					&:active {
						filter: brightness(1.1);
					}
				}
			}
		}

		.geolocation {
			@include make-container();
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	@include breakpoint(450px) {
		.mobile-nav-menu {
			max-width: 390px;
		}
	}

	@include breakpoint(1024px) {
		.mobile-nav-menu {
			&.app-banner-active {
				height: calc(100vh - 52px);
			}
		}
	}

	@include breakpoint(lg) {
		display: none;
	}
}

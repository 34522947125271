.hpr-image-top-cta-list {
	display: flex;
	flex-direction: column;
	gap: 1.65rem;

	h2 {
		margin-bottom: 0.375rem;
	}

	.ctas {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1.25rem;
	}

	@include breakpoint(md) {
		gap: 1.25rem;

		h2 {
			margin-bottom: 0;
		}

		.ctas {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 1.5rem;
		}
	}
}

/*
 *
 * COLOR VARIABLES
 *
 */

/* Monochromes */
$color-black: #000;
$color-neutral-darker: #181922;
$color-neutral-dark: #525259;
$color-neutral: #777;
$color-neutral-light: #ccc;
$color-gray: #888b8d;
$color-gray-dark: #666666;
$color-gray-light: #eee;
$color-gray-light-secondary: #ebf1fd;
$color-white: #fff;
$color-white-secondary: #f4f4f4;

/* Blues */
$color-blue-dark: #022243;
$color-blue-dark-secondary: #082241;
$color-blue-dark-tertiary: #003c72;
$color-blue-primary: #265698;
$color-blue-secondary: #0c5688;
$color-blue-tertiary: #0073cf;
$color-blue-light: #177fb4;

/* Other */
$color-red: #ab405b;
$color-critical: #e52b24;
$color-warning: #ffd200;
$color-gold: #ff8c00;
$color-yellow: #f0ab00;

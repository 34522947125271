.hpr-text-link {
	text-decoration: none;
	font-family: $font-family-primary;
	height: auto;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.hpr-geolocate-user {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	&.geolocating {
		.loader {
			display: inline-block;
		}
		.zip-input {
			display: none;
		}
		.icon-location {
			fill: $color-blue-dark;
		}
	}

	&.entering {
		.zip-input {
			border-color: $color-blue-light;
		}

		.icon-location {
			fill: $color-blue-light;
		}
	}

	.input-with-icon {
		display: flex;
		flex-direction: column;
		gap: 5px;

		.hpr-icon {
			height: 22px;
		}
	}

	.zip-input {
		@include text(18, 18);
		background-color: transparent;
		border-radius: 0;
		line-height: 1;
		min-height: 0;
		padding: 0;
		width: 50px;
		border: none;
		font-family: $font-family-tertiary;
		color: $color-blue-dark;
		font-weight: 700;
	}

	.icon-location {
		fill: $color-blue-tertiary;
	}

	.loader {
		display: none;
		font-family: $font-family-primary;
		width: 45px;

		&:after {
			// overflow: hidden;
			display: inline-block;
			animation: dotty steps(1, end) 1s infinite;
			content: '';

			@keyframes ellipsis {
				to {
					width: 80px;
				}
			}
		}
	}

	.error-message,
	.fallback-message {
		@include text(14);
		color: $color-red;
		display: none;
		font-family: $font-family-primary;
		opacity: 0;
		text-align: right;
		transition: opacity 0.2s;
		z-index: 10;
		text-align: left;
		width: 100%;
		margin-top: 5px;

		&.active {
			display: inline-block;
			opacity: 1;
		}
	}

	@keyframes dotty {
		0% {
			content: '';
		}
		25% {
			content: '.';
		}
		50% {
			content: '..';
		}
		75% {
			content: '...';
		}
		100% {
			content: '';
		}
	}

	@include breakpoint(lg) {
		padding: 0;

		.error-message,
		.fallback-message {
			background: white;
		}
	}
}

.hpr-layout-full-width-compact {
	background-color: $color-neutral;

	main {
		@include make-container-compact();
		padding-bottom: 2rem;
		padding-top: 2rem;
	}

	@include breakpoint(md) {
		main {
			padding-bottom: 50px;
			padding-top: 50px;
		}
	}
}

.hpr-services-and-providers-card {
	background-color: $color-gray-light;

	.services-card-container {
		@include make-container();
	}

	.card-inner {
		padding-bottom: 44px;
		padding-top: 28px;
	}

	.card-left {
		border-bottom: 1px solid $color-neutral-light;
		padding-bottom: 40px;
		margin-bottom: 40px;
	}

	.services-and-providers-main-heading,
	.services-and-providers-secondary-heading {
		@include text(iota, 21);
		margin-bottom: 18px;
	}

	.services-and-providers-list {
		margin-bottom: 20px;

		li {
			margin-bottom: 8px;

			a {
				@include text(kappa, 18);
				color: $color-blue-light;
				text-decoration: underline;
			}
		}
	}

	.hpr-image-card {
		margin-bottom: 30px;
	}

	.hpr-button {
		width: 100%;
	}

	.hpr-text-body {
		margin-bottom: 20px;
	}

	.buttons-container {
		.hpr-button {
			margin-bottom: 15px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	@include breakpoint(md) {
		background-color: $color-white;
		margin-bottom: 25px;
		margin-top: 75px;

		.card-container {
			padding: 0;
		}

		.card-inner {
			align-items: stretch;
			background-color: $color-gray-light;
			border-radius: 6px;
			display: flex;
			padding: 32px;
		}

		.card-left {
			padding: 0 36px 0 0;
			margin: 0;
			border-bottom: none;
			min-width: 50%;
			border-right: 1px solid $color-neutral-light;
			flex: 0 1 50%;
		}

		.services-and-providers-list {
			columns: 2;
			column-gap: 24px;
		}

		.card-right {
			flex: 0 1 50%;
			min-width: 50%;
			padding-left: 36px;
		}

		.hpr-image-card {
			.card-inner {
				padding: 0;
			}

			.image-card-info {
				.hpr-button {
					display: none;
				}
			}

			.hpr-button {
				display: block;
			}
		}

		.buttons-container {
			display: flex;
			gap: 22px;

			.hpr-button {
				flex: 0 1 50%;
				margin: 0;
				min-width: 0;
			}
		}

		.hpr-button {
			width: auto;
		}
	}

	@include breakpoint(1100px) {
		.hpr-image-card {
			.image-card-info {
				.hpr-button {
					display: block;
				}
			}

			.hpr-button {
				display: none;
			}
		}
	}
}

.hpr-header-search-bar {
	display: none;
	opacity: 0;
	height: 0;
	transition: all 0.5s ease;
	height: fit-content;
	opacity: 1;

	&.show {
		display: block;
	}

	&.visible {
		height: fit-content;
		opacity: 1;
	}

	.header-container {
		@include make-container();
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		padding-top: 32px;
		padding-bottom: 32px;

		> svg {
			width: 32px;
			height: 32px;
		}

		.hpr-search-form {
			flex: 1;

			.yxt-SearchBar {
				height: fit-content;

				.yxt-SearchBar-container {
					border: none;
					box-shadow: none !important;

					.yxt-SearchBar-form {
						.yxt-SearchBar-input {
							border-bottom: 1px solid $color-blue-dark;
							padding: 0rem 0.3rem;
							font-family: $font-family-secondary;
							font-weight: 400;
							box-shadow: none !important;
							font-size: 20px;

							&:focus,
							&:hover {
								border-bottom: 1px solid $color-blue-dark;
								background: none;
								border: initial;
								box-shadow: none !important;
							}
						}

						.yxt-SearchBar-button {
							margin-left: 0.8rem;
						}
					}
				}
			}
		}
	}
}

.patient-card {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 29px;
	width: 100%;

	.patient-card-image {
		border-radius: 6px;
		height: 108px;
		margin-bottom: 11px;
		object-fit: cover;
		object-position: center;
		width: 108px;
	}

	.patient-card-heading {
		@include text(zeta, 28);
		margin-bottom: 6px;
	}

	.hpr-text-body {
		margin-bottom: 12px;
	}

	.hpr-button {
		margin-bottom: 12px;
	}

	.patient-alternatives {
		align-items: center;
		display: flex;
		flex-direction: column;

		p {
			margin-bottom: 9px;
		}

		a {
			color: $color-blue-light;
			text-decoration: none;
			margin-bottom: 7px;
			width: auto;

			&:hover,
			&:focus {
				text-decoration: underline;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.alternative-byline {
		@include text(kappa, 18);
		font-family: $font-family-primary;
		font-weight: $font-weight-bold;
		margin-bottom: 11px;
	}

	.patient-services {
		p {
			@include text(lambda, 24);
			font-family: $font-family-primary;
			margin: 0;
		}
	}

	p {
		text-align: center;
		width: 100%;
	}

	.hpr-button {
		width: 100%;
	}

	@include breakpoint(md) {
		justify-content: flex-start;
		margin-bottom: 50px;

		.patient-card-heading {
			@include text(32, 37);
			margin-bottom: 8px;
		}

		.hpr-button {
			max-width: 350px;
		}
	}
}

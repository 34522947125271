.hpr-image-card {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;

	&.img-small {
		.card-inner {
			flex-direction: row;
			align-items: center;
			gap: 16px;

			.hpr-button {
				width: 100%;
				margin-top: 16px;
			}
		}

		.image-wrapper {
			flex: 0 1 50%;
			min-width: 50%;
		}

		.image-card-info {
			flex: 0 1 50%;
			min-width: 50%;
		}
	}

	.card-container {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	.card-inner {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;

		.image-wrapper {
			aspect-ratio: 1/1;
			border-radius: 6px;
			overflow: hidden;
			position: relative;
			width: 100%;

			img {
				border-radius: 6px;
				display: block;
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}

		.image-card-info {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			gap: 0.625rem;

			.image-card-info-text {
				display: flex;
				flex-direction: column;
				gap: 10px;

				h4 {
					@include text(iota, 18);
					margin: 0;
				}

				.hpr-text-link {
					// Replace
					color: $color-blue-primary;

					h4 {
						// Replace
						color: $color-blue-primary;
					}
				}

				.hpr-location-address {
					@include text(kappa, 18);
				}

				.hpr-location-links {
					display: flex;
					flex-direction: column;
					gap: 5px;

					a {
						// Replace
						color: $color-blue-primary;
					}
				}
			}

			.hpr-button {
				display: none;
				margin-top: 7px;

				+ .hpr-button {
					margin-top: 5px;
				}
			}
		}

		.hpr-button {
			display: block;
			width: 100%;
		}
	}

	@include breakpoint(md) {
		&.img-small {
			.image-wrapper {
				flex: 0 1 164px;
				min-width: 164px;
				max-width: 164px;
			}
		}

		.card-container {
			background-color: $color-gray-light;
			border-radius: 6px;
			padding: 1.25rem;

			.hpr-gray-bg & {
				background-color: $color-white;
			}
		}

		.card-inner {
			flex-direction: row;
			align-items: center;
			gap: 20px;

			.image-wrapper {
				align-self: flex-start;
				max-width: 218px;
			}

			.image-card-info {
				flex: 1;
				justify-content: space-between;

				.image-card-info-text {
					flex: 1;
					justify-content: center;
				}

				.hpr-button {
					margin-top: 3px;
				}
			}
		}
	}
}

.hpr-accordion {
	margin-bottom: 3rem;

	.accordion-item-wrapper {
		border-bottom: solid 1px $color-neutral;
		height: auto;
	}

	.accordion-item {
		@include text(theta);
		align-items: center;
		color: $color-black;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		padding: 15px 15px 15px 0;
		text-align: left;
		width: 100%;

		&:hover {
			color: $color-neutral;

			.hpr-icon {
				use {
					fill: $color-neutral;
				}
			}
		}

		.hpr-icon {
			use {
				fill: $color-black;
			}
		}

		.icon-chevron-down {
			display: block;
		}

		.icon-chevron-up {
			display: none;
		}

		&.active {
			.icon-chevron-down {
				display: none;
			}

			.icon-chevron-up {
				display: block;
			}
		}
	}

	.icon-chevron-down {
		float: right;
	}

	.icon-chevron-up {
		float: right;
	}

	.panel {
		display: none;

		p,
		span {
			@include text(15, 22);
			font-family: $font-family-secondary;
		}
	}

	@include breakpoint(md) {
		margin-bottom: 4rem;
	}
}

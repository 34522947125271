.hpr-hero {
	width: 100vw;
	overflow: hidden;
	position: relative;
	max-width: 100%;
	padding-top: 56.25%;
	height: 0;
	overflow: hidden;

	.hero-video {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		aspect-ratio: 16 / 9;

		&::-webkit-media-controls,
		&::-webkit-media-controls-start-playback-button {
			display: none;
		}
	}

	.hero-img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			aspect-ratio: 16 / 9;
		}
	}

	.cta-container {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	@include breakpoint(md) {
		height: 0;
		padding-top: calc(56 / 129 * 100%);

		.hero-video,
		.hero-img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			aspect-ratio: 129 / 56;
		}
	}
}

.hpr-services-contact-numbers {
	background-color: $color-gray-light;
	margin-bottom: 60px;
	padding-top: 30px;

	.services-contact-numbers-inner {
		display: flex;
		flex-direction: column;
	}

	.hpr-section-title {
		padding: 0 16px;
	}

	.hpr-services-list {
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	+ .hpr-small-cards-list {
		margin-top: -60px;
	}

	@include breakpoint(md) {
		padding: 60px 0;

		+ .hpr-small-cards-list {
			margin-top: -120px;
		}

		.services-contact-numbers-inner {
			@include make-container();
		}
	}
}

.hpr-select {
	position: relative;
	display: flex;

	&.select-secondary {
		select {
			background-color: transparent;
			width: 100%;

			&:hover {
				background-color: $color-black;
			}
		}
	}

	select {
		appearance: none;
		border: 1px solid $color-black;
		border-radius: 6px;
		color: $color-black;
		cursor: pointer;
		height: 2.25rem;
		padding-left: 1.25rem;
		padding-right: 2rem;
	}

	.hpr-icon {
		pointer-events: none;
		position: absolute;
		right: 0.85rem;
		top: 10px;
	}
}

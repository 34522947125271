/* Global (reset, functions, variables, mixins, helpers) */
/* Dont change order */
/* Reset */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: auto;
}
body.body-fixed {
  overflow-y: hidden;
  width: 100%;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  display: block;
  max-width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Strip border, outline, and padding from unclassed buttons */
button {
  background: transparent;
  border: 0;
  padding: 0;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}
.hpr-uci-app {
  position: relative;
}

/* Variables */
/*
 *
 * BREAKPOINTS MAP
 *
 */
/*
 *
 * COLOR VARIABLES
 *
 */
/* Monochromes */
/* Blues */
/* Other */
/* Box Shadow */
/*
 *
 * TYPOGRAPHY VARIABLES AND MAPS
 *
 */
/*
  * Font Size and Line Height Maps
  * Matching keys gets used together by default
  * Used in text() mixin and calulateLineHeight() function
  */
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicBold.woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicBoldItalic.woff");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicBoldItalicSub.woff");
  font-weight: bold;
  font-style: italic;
  font-feature-settings: "subs";
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicBoldSub.woff");
  font-weight: bold;
  font-style: normal;
  font-feature-settings: "subs";
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicExtraLight.woff");
  font-weight: 200; /* Extra Light */
  font-style: normal;
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicExtraLightItalic.woff");
  font-weight: 200; /* Extra Light */
  font-style: italic;
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicExtraLightItalicSub.woff");
  font-weight: 200; /* Extra Light */
  font-style: italic;
  font-feature-settings: "subs";
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicExtraLightSub.woff");
  font-weight: 200; /* Extra Light */
  font-style: normal;
  font-feature-settings: "subs";
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicLight.woff");
  font-weight: 300; /* Light */
  font-style: normal;
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicLightItalic.woff");
  font-weight: 300; /* Light */
  font-style: italic;
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicLightItalicSub.woff");
  font-weight: 300; /* Light */
  font-style: italic;
  font-feature-settings: "subs";
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicLightSub.woff");
  font-weight: 300; /* Light */
  font-style: normal;
  font-feature-settings: "subs";
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicMedium.woff");
  font-weight: 500; /* Medium */
  font-style: normal;
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicMediumItalic.woff");
  font-weight: 500; /* Medium */
  font-style: italic;
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicMediumItalicSub.woff");
  font-weight: 500; /* Medium */
  font-style: italic;
  font-feature-settings: "subs";
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicMediumSub.woff");
  font-weight: 500; /* Medium */
  font-style: normal;
  font-feature-settings: "subs";
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicRegular.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicRegularItalic.woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicRegularItalicSub.woff");
  font-weight: normal;
  font-style: italic;
  font-feature-settings: "subs";
}
@font-face {
  font-family: "Seravek";
  src: url("/assets/fonts/seravek/SeravekWebBasicRegularSub.woff");
  font-weight: normal;
  font-style: normal;
  font-feature-settings: "subs";
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-BlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-BookItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-Extlight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-ExtlightItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-Extrabold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-ExtraboldItalic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT-ThinItalic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Kievit Slab OT";
  src: url("/assets/fonts/kievit/KievitSlabOT.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
/* Functions */
/*
 *
 * TYPOGRAPHY FUNCTIONS
 *
 */
/*
 * calculateRem($font-size, $base)
 * convert absolute (px) into relative em (rem) values
 * assumes a 16px baseline; override if in a different context
 * -----
 * calculateRem(32)      ==> 0.5rem
 * calculateRem(48, 12)  ==> 4rem
 */
/*
 * calulateLineHeight($font-size, $line-height)
 * convert absolute (px) from corresponding line-heights map into unitless line-height values
 * $font-size argument must correlate to $font-size-scale in _variables.scss to avoid errant font-sizes
 * $line-height argument can be correlated to $line-height-scale (ie alpha, beta) in _variables.scss or a specific number value (ie 27, 32)
 */
/* Mixins */
/*
 * highlight($color (optional, default $color-bright-blue)))
 * adds background highlight and ltr animation - defaults to $color-bright-blue, but can accept other colors
 */
/*
 * loader($color, $height(optional, default 26px), $stroke(optional, default 2px))
 * adds generic loader as :after psuedo element
 * $color must be passed. $height and $stroke have defaults, but can be passed in as needed.
 */
/*
 * pulse-highlight($color)
 * adds pulse highlight to circular element with equal height and width
 * $color must be passed
 */
/*
 * text($font-size, $line-height(optional))
 * calculates font-size and line-height corresponding to $font-size-scale and $line-height-scale maps in _variables.scss
 * $line-height argument is optional, and can be a different value in $line-height-scale or a number value
 */
/* Helpers */
@media (min-width: 768px) {
  .two-column {
    columns: 2;
  }
}

@media (min-width: 768px) {
  .three-column {
    columns: 2;
  }
}
@media (min-width: 1280px) {
  .three-column {
    columns: 3;
  }
}

@media (min-width: 768px) {
  .four-column {
    columns: 2;
  }
}
@media (min-width: 1280px) {
  .four-column {
    columns: 3;
  }
}
@media (min-width: 1440px) {
  .four-column {
    columns: 4;
  }
}

.hpr-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
}
@media (min-width: 768px) {
  .hpr-container {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}

.container-bg {
  margin-right: auto;
  margin-left: auto;
  max-width: calc(1280px - 16px * 2);
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
}
@media (min-width: 768px) {
  .container-bg {
    max-width: calc(1280px - 32px * 2);
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .container-bg {
    max-width: calc(1280px - 88px * 2);
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .container-bg {
    max-width: calc(1280px - 88px * 2);
    padding-right: 88px;
    padding-left: 88px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.grid-col {
  width: 100%;
}

.grid-col,
[class*=grid-col-] {
  flex: 1 1 0;
  margin-bottom: 0;
  padding: 0 12px;
}
.grid-col.position-center,
[class*=grid-col-].position-center {
  margin: auto;
}

.grid-col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
  margin-bottom: 0;
  padding: 0 12px;
}

.grid-col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
  margin-bottom: 0;
  padding: 0 12px;
}

.grid-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
  margin-bottom: 0;
  padding: 0 12px;
}

.grid-col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  margin-bottom: 0;
  padding: 0 12px;
}

.grid-col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
  margin-bottom: 0;
  padding: 0 12px;
}

.grid-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 0;
  padding: 0 12px;
}

.grid-col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
  margin-bottom: 0;
  padding: 0 12px;
}

.grid-col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
  margin-bottom: 0;
  padding: 0 12px;
}

.grid-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
  margin-bottom: 0;
  padding: 0 12px;
}

.grid-col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  margin-bottom: 0;
  padding: 0 12px;
}

.grid-col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
  margin-bottom: 0;
  padding: 0 12px;
}

.grid-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 0;
  padding: 0 12px;
}

@media (min-width: 768px) {
  .grid-col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .justify-content-md-center {
    justify-content: center;
  }
}
@media (min-width: 1280px) {
  .grid-col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
}
@media (min-width: 1440px) {
  .grid-col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .grid-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
}
.layout-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .layout-wrapper {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .layout-wrapper {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .layout-wrapper {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.layout-wrapper section {
  margin-bottom: 2rem;
}
.layout-wrapper .column-main,
.layout-wrapper .column-sidebar {
  padding-bottom: 3rem;
}
.layout-wrapper .column-sidebar {
  display: flex;
  flex-wrap: wrap;
}
.layout-wrapper .column-sidebar > section {
  margin-bottom: 1.5625rem;
  margin-right: 1.5625rem;
}
@media (min-width: 1280px) {
  .layout-wrapper section {
    margin-bottom: 3.125rem;
  }
  .layout-wrapper .column-sidebar {
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
  }
  .layout-wrapper .column-sidebar > section {
    margin-right: 0;
  }
}

.hpr-uci-app {
  -webkit-font-smoothing: auto;
}
.hpr-uci-app > section {
  margin-bottom: 2rem;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .hpr-uci-app > section {
    margin-bottom: 3.125rem;
  }
}

@media (min-width: 768px) {
  ol.two-columns, ol.three-columns, ol.four-columns,
  ul.two-columns,
  ul.three-columns,
  ul.four-columns {
    columns: 2;
    column-gap: 1rem;
  }
}
@media (min-width: 1280px) {
  ol.three-columns, ol.four-columns,
  ul.three-columns,
  ul.four-columns {
    columns: 3;
    column-gap: 1rem;
  }
}
@media (min-width: 1440px) {
  ol.four-columns,
  ul.four-columns {
    columns: 4;
    column-gap: 1rem;
  }
}

.styleguide-header {
  padding-top: 2rem;
  margin-bottom: 2rem;
}

.styleguide-divider {
  font-size: 1rem;
  line-height: 1.375;
  align-items: center;
  display: flex;
  margin: 3rem -1rem 1rem;
  text-transform: uppercase;
}
.styleguide-divider:before, .styleguide-divider:after {
  content: "";
  flex: 1;
  height: 1px;
  margin: 0 1rem;
}
.styleguide-divider:before {
  background: linear-gradient(to right, #f6f3f1, black);
}
.styleguide-divider:after {
  background: linear-gradient(to left, #f6f3f1, black);
}

.styleguide-notes {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  text-align: center;
  font-family: "Seravek", sans-serif;
}

.styleguide-list {
  margin-top: 1rem;
}
.styleguide-list li {
  padding: 0.375rem 0;
}

/* Atoms (basic HTML elements e.g. inputs, labels, buttons) */
.hpr-avatar {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  width: 66px;
  height: 66px;
}

button[class^=hpr-],
button[class*=hpr-],
[class*=hpr-] button,
.hpr-uci-app button {
  background: transparent;
  border: 0;
  border-radius: 0;
  margin: 0;
  text-transform: none;
  height: auto;
  padding: 0 !important;
}

[class*=hpr-] .hpr-button,
.hpr-uci-app .hpr-button,
.hpr-button {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  font-family: "Seravek", sans-serif;
  margin: 0;
  padding: 0.938rem 1rem !important;
  border-radius: 0.25rem;
  background: #265698;
  transition: all 0.3s ease;
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
  text-decoration: none;
}
[class*=hpr-] .hpr-button:hover, [class*=hpr-] .hpr-button:active,
.hpr-uci-app .hpr-button:hover,
.hpr-uci-app .hpr-button:active,
.hpr-button:hover,
.hpr-button:active {
  filter: brightness(1.2);
}
[class*=hpr-] .hpr-button.button-secondary,
.hpr-uci-app .hpr-button.button-secondary,
.hpr-button.button-secondary {
  background-color: transparent;
  border: 1px solid #265698;
  color: #265698;
}
[class*=hpr-] .hpr-button.button-secondary:hover, [class*=hpr-] .hpr-button.button-secondary:active,
.hpr-uci-app .hpr-button.button-secondary:hover,
.hpr-uci-app .hpr-button.button-secondary:active,
.hpr-button.button-secondary:hover,
.hpr-button.button-secondary:active {
  background-color: #265698;
  color: #fff;
  filter: brightness(1.2);
}
[class*=hpr-] .hpr-button.button-tertiary,
.hpr-uci-app .hpr-button.button-tertiary,
.hpr-button.button-tertiary {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
}
[class*=hpr-] .hpr-button.button-tertiary:hover, [class*=hpr-] .hpr-button.button-tertiary:active,
.hpr-uci-app .hpr-button.button-tertiary:hover,
.hpr-uci-app .hpr-button.button-tertiary:active,
.hpr-button.button-tertiary:hover,
.hpr-button.button-tertiary:active {
  background-color: #fff;
}
[class*=hpr-] .hpr-button.button-outline,
.hpr-uci-app .hpr-button.button-outline,
.hpr-button.button-outline {
  background-color: transparent;
  border: 1px solid #265698;
  color: #265698;
}
[class*=hpr-] .hpr-button.button-outline:hover, [class*=hpr-] .hpr-button.button-outline:active,
.hpr-uci-app .hpr-button.button-outline:hover,
.hpr-uci-app .hpr-button.button-outline:active,
.hpr-button.button-outline:hover,
.hpr-button.button-outline:active {
  background-color: #265698;
  color: #fff;
}
[class*=hpr-] .hpr-button.button-outline-white,
.hpr-uci-app .hpr-button.button-outline-white,
.hpr-button.button-outline-white {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
[class*=hpr-] .hpr-button.button-outline-white:hover, [class*=hpr-] .hpr-button.button-outline-white:active,
.hpr-uci-app .hpr-button.button-outline-white:hover,
.hpr-uci-app .hpr-button.button-outline-white:active,
.hpr-button.button-outline-white:hover,
.hpr-button.button-outline-white:active {
  background-color: #fff;
  color: #000;
}
[class*=hpr-] .hpr-button.disabled,
.hpr-uci-app .hpr-button.disabled,
.hpr-button.disabled {
  background-color: #777;
  border: 1px solid #777;
  color: #000;
  cursor: default;
}
[class*=hpr-] .hpr-button.disabled:hover, [class*=hpr-] .hpr-button.disabled:active,
.hpr-uci-app .hpr-button.disabled:hover,
.hpr-uci-app .hpr-button.disabled:active,
.hpr-button.disabled:hover,
.hpr-button.disabled:active {
  cursor: default;
}
@media (min-width: 1280px) {
  [class*=hpr-] .hpr-button,
  .hpr-uci-app .hpr-button,
  .hpr-button {
    min-width: 259px;
  }
}

.hpr-button-link {
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-decoration: none;
  transition: filter 0.3s ease;
  padding: 0.7rem 1.5rem;
  font-family: "Seravek", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  border-radius: 0.625rem;
}
.hpr-button-link:hover {
  filter: brightness(1.1);
}

.hpr-close-btn {
  align-items: center;
  background-color: #525259;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  flex: 0 0 40px;
  height: 40px;
  justify-content: center;
  margin-bottom: 0.75rem;
  padding: 0 1.25rem;
  padding: 0;
  width: 40px;
}
.hpr-close-btn .hpr-icon.icon-close {
  stroke: #fff;
}

button.hpr-hamburger-button {
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 33px;
  height: 36px;
  position: relative;
}
button.hpr-hamburger-button .hamburger-line {
  background-color: #fff;
  height: 3px;
  width: 100%;
  transition: all 0.1s ease-in-out;
  border-radius: 4px;
}
button.hpr-hamburger-button.open .hamburger-line:nth-child(1) {
  transform: translateY(12px) rotate(45deg);
}
button.hpr-hamburger-button.open .hamburger-line:nth-child(2) {
  opacity: 0;
}
button.hpr-hamburger-button.open .hamburger-line:nth-child(3) {
  transform: translateY(-12px) rotate(-45deg);
}

[class^=hpr-] h1 {
  font-size: 3.375rem;
  line-height: 1;
  color: #000;
  font-family: "Seravek", sans-serif;
  font-weight: 700;
  margin-top: 0;
}
[class^=hpr-] h2 {
  font-size: 2rem;
  line-height: 1;
  color: #000;
  font-family: "Seravek", sans-serif;
  font-weight: 700;
  margin-top: 0;
}
[class^=hpr-] h3 {
  font-size: 1.5rem;
  line-height: 1;
  color: #000;
  font-family: "Seravek", sans-serif;
  font-weight: 700;
  margin-top: 0;
}
[class^=hpr-] h4 {
  font-size: 1.125rem;
  line-height: 1;
  color: #000;
  font-family: "Seravek", sans-serif;
  font-weight: 700;
  margin-top: 0;
}

.hpr-page-title {
  font-size: 2rem;
  line-height: 1.125;
  margin-bottom: 26px;
}
@media (min-width: 768px) {
  .hpr-page-title {
    margin-bottom: 50px;
  }
}

.hpr-section-title {
  font-size: 1.125rem;
  line-height: 1.1666666667;
  font-family: "Seravek", sans-serif;
  font-weight: 700;
  margin: 0 auto 30px;
  max-width: 800px;
}
.hpr-section-title + .hpr-section-title {
  margin-top: -20px;
}
.hpr-section-title a {
  color: #177fb4;
}
@media (min-width: 768px) {
  .hpr-section-title {
    font-size: 1.5rem;
    line-height: 1.1666666667;
    margin-bottom: 60px;
  }
  .hpr-section-title + .hpr-section-title {
    margin-top: -50px;
  }
}

.hpr-divider-title {
  align-items: center;
  display: flex;
  margin-bottom: 30px;
  text-align: center;
  flex: 1;
  gap: 6px;
}
.hpr-divider-title:before, .hpr-divider-title:after {
  content: "";
  flex-grow: 1;
  margin: 0px 4px;
  height: 1px;
  background: #ccc;
}
@media (min-width: 768px) {
  .hpr-divider-title {
    margin-bottom: 40px;
  }
}

.hpr-text-center {
  text-align: center;
}

.hpr-icon {
  height: 1rem;
  stroke-linecap: round;
  width: 1rem;
}
.hpr-icon use path {
  all: inherit;
}

.hpr-location-miles-distance {
  display: block;
  width: fit-content;
}
.hpr-location-miles-distance .address-hidden {
  display: none;
}

.hpr-radio-wrapper {
  align-items: flex-start;
  display: flex;
  justify-items: flex-start;
  margin-bottom: 0.75rem;
  position: relative;
}
.hpr-radio-wrapper.disabled {
  pointer-events: none;
}
.hpr-radio-wrapper.disabled input[type=radio] + label {
  color: #777;
}
.hpr-radio-wrapper input[type=radio] {
  height: 0;
  margin: 0;
  opacity: 0;
  width: 0;
}
.hpr-radio-wrapper input[type=radio]:checked + label {
  background-color: #525259;
  color: #fff;
  cursor: default;
}
.hpr-radio-wrapper input[type=radio]:checked + label:after {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  content: "";
  display: block;
  height: 0.9rem;
  right: 20px;
  margin: 0.25rem 0.5rem;
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translate(calc(-75% - 1px), calc(-75% - 1px));
  width: 0.45rem;
  border-bottom-width: 1px;
  border-right-width: 1px;
}
.hpr-radio-wrapper input[type=radio]:checked + label .label-number {
  visibility: hidden;
}
.hpr-radio-wrapper input[type=radio]:hover {
  cursor: pointer;
}
.hpr-radio-wrapper label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 0 0 0.5rem;
  width: 100%;
  background-color: #fff;
  border-radius: 25px;
  padding: 0.625rem 1.25rem;
}
.hpr-radio-wrapper label:hover, .hpr-radio-wrapper label:focus, .hpr-radio-wrapper label[aria-selected=true] {
  background-color: #ccc;
}
.hpr-radio-wrapper label .label-display-name + .label-name {
  text-align: right;
}
.hpr-radio-wrapper label .label-name {
  flex-grow: 1;
}
.hpr-radio-wrapper label .label-number {
  padding-left: 1rem;
}

.hpr-select {
  position: relative;
  display: flex;
}
.hpr-select.select-secondary select {
  background-color: transparent;
  width: 100%;
}
.hpr-select.select-secondary select:hover {
  background-color: #000;
}
.hpr-select select {
  appearance: none;
  border: 1px solid #000;
  border-radius: 6px;
  color: #000;
  cursor: pointer;
  height: 2.25rem;
  padding-left: 1.25rem;
  padding-right: 2rem;
}
.hpr-select .hpr-icon {
  pointer-events: none;
  position: absolute;
  right: 0.85rem;
  top: 10px;
}

.hpr-separator {
  border-style: none;
  width: 100%;
  height: 1px;
  background-color: #777;
  margin: 2rem 0;
}

.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

.hpr-social-link {
  padding: 0 10px;
}
.hpr-social-link:last-child() {
  padding-right: 0;
}
.hpr-social-link .hpr-icon {
  height: 35px;
  width: 35px;
}
.hpr-social-link:hover .hpr-icon {
  display: none;
}
.hpr-social-link:hover .hpr-icon[class$=-hover] {
  display: block;
}
.hpr-social-link .hpr-icon[class$=-hover] {
  display: none;
}

.hpr-star {
  display: inline-block;
  position: relative;
  height: 21px;
  width: 22px;
}
.hpr-star:last-child {
  margin-right: 0;
}
.hpr-star svg {
  fill: #777;
  height: 21px;
  width: 22px;
  position: absolute;
  top: 0;
  left: 0;
}
.hpr-star .star-fill {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  height: 21px;
}
.hpr-star .star-fill svg {
  fill: #ff8c00;
}

.hpr-text-body {
  font-size: 1rem;
  line-height: 1.25;
  color: #000;
  font-family: "Seravek", sans-serif;
  font-weight: 400;
  margin: 0;
}

.hpr-text-link {
  text-decoration: none;
  font-family: "Seravek", sans-serif;
  height: auto;
  cursor: pointer;
}
.hpr-text-link:hover {
  text-decoration: underline;
}

.hpr-text-truncated {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-height: 3rem;
}

#answers-container {
  padding-bottom: 1.5rem;
}

/* Molecules (e.g. Hero, Primary Navigation, Search) */
.hpr-accordion {
  margin-bottom: 3rem;
}
.hpr-accordion .accordion-item-wrapper {
  border-bottom: solid 1px #777;
  height: auto;
}
.hpr-accordion .accordion-item {
  font-size: 1.25rem;
  line-height: 1.5;
  align-items: center;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 15px 0;
  text-align: left;
  width: 100%;
}
.hpr-accordion .accordion-item:hover {
  color: #777;
}
.hpr-accordion .accordion-item:hover .hpr-icon use {
  fill: #777;
}
.hpr-accordion .accordion-item .hpr-icon use {
  fill: #000;
}
.hpr-accordion .accordion-item .icon-chevron-down {
  display: block;
}
.hpr-accordion .accordion-item .icon-chevron-up {
  display: none;
}
.hpr-accordion .accordion-item.active .icon-chevron-down {
  display: none;
}
.hpr-accordion .accordion-item.active .icon-chevron-up {
  display: block;
}
.hpr-accordion .icon-chevron-down {
  float: right;
}
.hpr-accordion .icon-chevron-up {
  float: right;
}
.hpr-accordion .panel {
  display: none;
}
.hpr-accordion .panel p,
.hpr-accordion .panel span {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Open Sans", sans-serif;
}
@media (min-width: 768px) {
  .hpr-accordion {
    margin-bottom: 4rem;
  }
}

.hpr-app-banner {
  z-index: 20;
  width: 100%;
  padding: 0.8rem;
  background-color: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.hpr-app-banner.hidden {
  display: none;
}
.hpr-app-banner .app-banner-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.hpr-app-banner .app-banner-content .app-banner-text {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.hpr-app-banner .app-banner-content .app-banner-text h4 {
  font-size: 1rem;
}
.hpr-app-banner .app-banner-content .app-banner-text p {
  font-size: 0.813rem;
  color: #666666;
}
.hpr-app-banner .hpr-button.hidden {
  display: none;
}
@media (min-width: 1024px) {
  .hpr-app-banner {
    display: none;
  }
}

.hpr-cta-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.875rem;
  width: 100%;
}
.hpr-cta-one p {
  width: 100%;
  text-align: center;
}
.hpr-cta-one a {
  width: 100%;
  max-width: 245px;
}
@media (min-width: 414px) {
  .hpr-cta-one {
    max-width: 325px;
  }
}

.hpr-full-background-cta {
  width: 100%;
  position: relative;
}
.hpr-full-background-cta .background-image {
  position: relative;
}
.hpr-full-background-cta .background-image .background-image-wrapper {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 72.2222222222%;
}
.hpr-full-background-cta .background-image .background-image-wrapper .background-image-picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 18/13;
}
.hpr-full-background-cta .background-image .background-image-wrapper .background-image-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hpr-full-background-cta .full-background-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .hpr-full-background-cta .full-background-container {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-full-background-cta .full-background-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-full-background-cta .full-background-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-full-background-cta .full-background-container .container-inner {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.hpr-full-background-cta .full-background-container .container-inner .full-background-cta-title,
.hpr-full-background-cta .full-background-container .container-inner h2 {
  font-size: 2rem;
  line-height: 1;
  text-align: left;
}
.hpr-full-background-cta .full-background-container .container-inner p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.hpr-full-background-cta .full-background-container .container-inner .hpr-button {
  margin-top: 6px;
}
@media (min-width: 768px) {
  .hpr-full-background-cta {
    height: 0;
    width: 100%;
    position: relative;
    padding-top: 35.1288056206%;
  }
  .hpr-full-background-cta.white-text h2,
  .hpr-full-background-cta.white-text p {
    color: #fff;
  }
  .hpr-full-background-cta .background-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .hpr-full-background-cta .background-image .background-image-wrapper {
    padding-top: 35.1288056206%;
  }
  .hpr-full-background-cta .background-image .background-image-wrapper .background-image-picture {
    aspect-ratio: 1281/450;
  }
  .hpr-full-background-cta .full-background-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .hpr-full-background-cta .full-background-container .container-inner {
    width: 50%;
    align-self: flex-end;
  }
  .hpr-full-background-cta .full-background-container .container-inner .hpr-button {
    max-width: 325px;
  }
}
@media (min-width: 870px) {
  .hpr-full-background-cta {
    padding-top: 26.3856362217%;
  }
  .hpr-full-background-cta .background-image .background-image-wrapper {
    padding-top: 26.3856362217%;
  }
  .hpr-full-background-cta .background-image .background-image-wrapper .background-image-picture {
    aspect-ratio: 1281/338;
  }
}

.hpr-geolocate-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.hpr-geolocate-user.geolocating .loader {
  display: inline-block;
}
.hpr-geolocate-user.geolocating .zip-input {
  display: none;
}
.hpr-geolocate-user.geolocating .icon-location {
  fill: #022243;
}
.hpr-geolocate-user.entering .zip-input {
  border-color: #177fb4;
}
.hpr-geolocate-user.entering .icon-location {
  fill: #177fb4;
}
.hpr-geolocate-user .input-with-icon {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.hpr-geolocate-user .input-with-icon .hpr-icon {
  height: 22px;
}
.hpr-geolocate-user .zip-input {
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border-radius: 0;
  line-height: 1;
  min-height: 0;
  padding: 0;
  width: 50px;
  border: none;
  font-family: "Seravek", sans-serif;
  color: #022243;
  font-weight: 700;
}
.hpr-geolocate-user .icon-location {
  fill: #0073cf;
}
.hpr-geolocate-user .loader {
  display: none;
  font-family: "Seravek", sans-serif;
  width: 45px;
}
.hpr-geolocate-user .loader:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: "";
}
@keyframes ellipsis {
  to {
    width: 80px;
  }
}
.hpr-geolocate-user .error-message,
.hpr-geolocate-user .fallback-message {
  font-size: 0.875rem;
  line-height: 1;
  color: #ab405b;
  display: none;
  font-family: "Seravek", sans-serif;
  opacity: 0;
  text-align: right;
  transition: opacity 0.2s;
  z-index: 10;
  text-align: left;
  width: 100%;
  margin-top: 5px;
}
.hpr-geolocate-user .error-message.active,
.hpr-geolocate-user .fallback-message.active {
  display: inline-block;
  opacity: 1;
}
@keyframes dotty {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}
@media (min-width: 1280px) {
  .hpr-geolocate-user {
    padding: 0;
  }
  .hpr-geolocate-user .error-message,
  .hpr-geolocate-user .fallback-message {
    background: white;
  }
}

.hpr-header-search-bar {
  display: none;
  opacity: 0;
  height: 0;
  transition: all 0.5s ease;
  height: fit-content;
  opacity: 1;
}
.hpr-header-search-bar.show {
  display: block;
}
.hpr-header-search-bar.visible {
  height: fit-content;
  opacity: 1;
}
.hpr-header-search-bar .header-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding-top: 32px;
  padding-bottom: 32px;
}
@media (min-width: 768px) {
  .hpr-header-search-bar .header-container {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-header-search-bar .header-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-header-search-bar .header-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-header-search-bar .header-container > svg {
  width: 32px;
  height: 32px;
}
.hpr-header-search-bar .header-container .hpr-search-form {
  flex: 1;
}
.hpr-header-search-bar .header-container .hpr-search-form .yxt-SearchBar {
  height: fit-content;
}
.hpr-header-search-bar .header-container .hpr-search-form .yxt-SearchBar .yxt-SearchBar-container {
  border: none;
  box-shadow: none !important;
}
.hpr-header-search-bar .header-container .hpr-search-form .yxt-SearchBar .yxt-SearchBar-container .yxt-SearchBar-form .yxt-SearchBar-input {
  border-bottom: 1px solid #022243;
  padding: 0rem 0.3rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  box-shadow: none !important;
  font-size: 20px;
}
.hpr-header-search-bar .header-container .hpr-search-form .yxt-SearchBar .yxt-SearchBar-container .yxt-SearchBar-form .yxt-SearchBar-input:focus, .hpr-header-search-bar .header-container .hpr-search-form .yxt-SearchBar .yxt-SearchBar-container .yxt-SearchBar-form .yxt-SearchBar-input:hover {
  border-bottom: 1px solid #022243;
  background: none;
  border: initial;
  box-shadow: none !important;
}
.hpr-header-search-bar .header-container .hpr-search-form .yxt-SearchBar .yxt-SearchBar-container .yxt-SearchBar-form .yxt-SearchBar-button {
  margin-left: 0.8rem;
}

.hpr-hero {
  width: 100vw;
  overflow: hidden;
  position: relative;
  max-width: 100%;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
}
.hpr-hero .hero-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
}
.hpr-hero .hero-video::-webkit-media-controls, .hpr-hero .hero-video::-webkit-media-controls-start-playback-button {
  display: none;
}
.hpr-hero .hero-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}
.hpr-hero .hero-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
}
.hpr-hero .cta-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .hpr-hero {
    height: 0;
    padding-top: 43.4108527132%;
  }
  .hpr-hero .hero-video,
  .hpr-hero .hero-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 129/56;
  }
}

.hpr-hero-cta {
  width: 100%;
  background-color: rgba(2, 34, 67, 0.35);
}
.hpr-hero-cta .hero-cta-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
@media (min-width: 768px) {
  .hpr-hero-cta .hero-cta-container {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-hero-cta .hero-cta-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-hero-cta .hero-cta-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-hero-cta .hero-cta-container h5 {
  font-size: 1.625rem;
  line-height: 1;
  color: #fff;
  font-family: "Kievit Slab OT", serif;
  font-weight: 300;
  margin: 0;
}
.hpr-hero-cta .hero-cta-container a {
  border-radius: 6px;
  width: 100%;
  max-width: 330px;
}
@media (min-width: 768px) {
  .hpr-hero-cta .hero-cta-container {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .hpr-hero-cta .hero-cta-container h5 {
    font-size: 2rem;
    line-height: 1.5625;
  }
  .hpr-hero-cta .hero-cta-container a {
    width: fit-content;
  }
}
@media (min-width: 850px) {
  .hpr-hero-cta .hero-cta-container h5 {
    font-size: 2.625rem;
    line-height: 1.4285714286;
  }
}

.hpr-image-top-cta {
  background-color: #eee;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 352px;
  border-radius: 6px;
}
.hpr-image-top-cta .image-container {
  height: 0;
  width: 100%;
  padding-top: 62.5%;
  position: relative;
}
.hpr-image-top-cta .image-container img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 352/220;
  border-radius: 6px 6px 0px 0px;
  object-fit: cover;
}
.hpr-image-top-cta .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  padding: 1.25rem;
}
.hpr-image-top-cta .content .hpr-text-truncated {
  max-height: 4rem;
  -webkit-line-clamp: 3;
}
.hpr-image-top-cta .content a {
  margin-top: 0.563rem;
}

.hpr-image-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.hpr-image-card.img-small .card-inner {
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.hpr-image-card.img-small .card-inner .hpr-button {
  width: 100%;
  margin-top: 16px;
}
.hpr-image-card.img-small .image-wrapper {
  flex: 0 1 50%;
  min-width: 50%;
}
.hpr-image-card.img-small .image-card-info {
  flex: 0 1 50%;
  min-width: 50%;
}
.hpr-image-card .card-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.hpr-image-card .card-inner {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.hpr-image-card .card-inner .image-wrapper {
  aspect-ratio: 1/1;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.hpr-image-card .card-inner .image-wrapper img {
  border-radius: 6px;
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.hpr-image-card .card-inner .image-card-info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
.hpr-image-card .card-inner .image-card-info .image-card-info-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.hpr-image-card .card-inner .image-card-info .image-card-info-text h4 {
  font-size: 1.125rem;
  line-height: 1;
  margin: 0;
}
.hpr-image-card .card-inner .image-card-info .image-card-info-text .hpr-text-link {
  color: #265698;
}
.hpr-image-card .card-inner .image-card-info .image-card-info-text .hpr-text-link h4 {
  color: #265698;
}
.hpr-image-card .card-inner .image-card-info .image-card-info-text .hpr-location-address {
  font-size: 1rem;
  line-height: 1.125;
}
.hpr-image-card .card-inner .image-card-info .image-card-info-text .hpr-location-links {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.hpr-image-card .card-inner .image-card-info .image-card-info-text .hpr-location-links a {
  color: #265698;
}
.hpr-image-card .card-inner .image-card-info .hpr-button {
  display: none;
  margin-top: 7px;
}
.hpr-image-card .card-inner .image-card-info .hpr-button + .hpr-button {
  margin-top: 5px;
}
.hpr-image-card .card-inner .hpr-button {
  display: block;
  width: 100%;
}
@media (min-width: 768px) {
  .hpr-image-card.img-small .image-wrapper {
    flex: 0 1 164px;
    min-width: 164px;
    max-width: 164px;
  }
  .hpr-image-card .card-container {
    background-color: #eee;
    border-radius: 6px;
    padding: 1.25rem;
  }
  .hpr-gray-bg .hpr-image-card .card-container {
    background-color: #fff;
  }
  .hpr-image-card .card-inner {
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  .hpr-image-card .card-inner .image-wrapper {
    align-self: flex-start;
    max-width: 218px;
  }
  .hpr-image-card .card-inner .image-card-info {
    flex: 1;
    justify-content: space-between;
  }
  .hpr-image-card .card-inner .image-card-info .image-card-info-text {
    flex: 1;
    justify-content: center;
  }
  .hpr-image-card .card-inner .image-card-info .hpr-button {
    margin-top: 3px;
  }
}

.patient-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 29px;
  width: 100%;
}
.patient-card .patient-card-image {
  border-radius: 6px;
  height: 108px;
  margin-bottom: 11px;
  object-fit: cover;
  object-position: center;
  width: 108px;
}
.patient-card .patient-card-heading {
  font-size: 1.5rem;
  line-height: 1.1666666667;
  margin-bottom: 6px;
}
.patient-card .hpr-text-body {
  margin-bottom: 12px;
}
.patient-card .hpr-button {
  margin-bottom: 12px;
}
.patient-card .patient-alternatives {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.patient-card .patient-alternatives p {
  margin-bottom: 9px;
}
.patient-card .patient-alternatives a {
  color: #177fb4;
  text-decoration: none;
  margin-bottom: 7px;
  width: auto;
}
.patient-card .patient-alternatives a:hover, .patient-card .patient-alternatives a:focus {
  text-decoration: underline;
}
.patient-card .patient-alternatives a:last-of-type {
  margin-bottom: 0;
}
.patient-card .alternative-byline {
  font-size: 1rem;
  line-height: 1.125;
  font-family: "Seravek", sans-serif;
  font-weight: 700;
  margin-bottom: 11px;
}
.patient-card .patient-services p {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  font-family: "Seravek", sans-serif;
  margin: 0;
}
.patient-card p {
  text-align: center;
  width: 100%;
}
.patient-card .hpr-button {
  width: 100%;
}
@media (min-width: 768px) {
  .patient-card {
    justify-content: flex-start;
    margin-bottom: 50px;
  }
  .patient-card .patient-card-heading {
    font-size: 2rem;
    line-height: 1.15625;
    margin-bottom: 8px;
  }
  .patient-card .hpr-button {
    max-width: 350px;
  }
}

.hpr-services-list {
  margin-bottom: 30px;
}
.hpr-services-list li {
  font-size: 1rem;
  line-height: 1.125;
  align-items: center;
  background-color: #fff;
  color: #000;
  display: flex;
  font-family: "Seravek", sans-serif;
  justify-content: space-between;
  padding: 10px;
}
.hpr-services-list li:nth-of-type(even) {
  background-color: #eee;
}
.hpr-services-list .item-link {
  color: #177fb4;
  margin-left: 2rem;
  flex: 0 0 auto;
}
@media (min-width: 768px) {
  .hpr-services-list {
    columns: 2;
    column-gap: 24px;
    margin-bottom: 40px;
  }
}

.hpr-urgency-banner {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  background-color: #ffd200;
  color: #000;
  font-family: "Seravek", sans-serif;
  font-weight: 700;
  padding: 11px 32px 16px;
  text-align: center;
  text-transform: uppercase;
}
.hpr-urgency-banner p:last-of-type {
  margin-bottom: 0;
}
.hpr-urgency-banner .urgency-banner-content {
  background-color: #ffd200;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
}
.hpr-urgency-banner .urgency-banner-content a {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #265698;
}
.hpr-urgency-banner .urgency-banner-content h2 {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #000;
}
.hpr-urgency-banner .urgency-banner-content h2 a {
  color: #265698;
}
@media (min-width: 768px) {
  .hpr-urgency-banner {
    padding: 9px 0;
  }
  .hpr-urgency-banner .urgency-banner-content {
    margin-right: auto;
    margin-left: auto;
    max-width: 1280px;
    padding-right: 16px;
    padding-left: 16px;
    width: 100%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .hpr-urgency-banner .urgency-banner-content {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .hpr-urgency-banner .urgency-banner-content {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 768px) and (min-width: 1440px) {
  .hpr-urgency-banner .urgency-banner-content {
    padding-right: 88px;
    padding-left: 88px;
  }
}

/* Organisms (e.g. Header, Footer, Article, SitecoreComponent) */
.hpr-cta-one-list {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
}
.hpr-cta-one-list .hpr-divider {
  display: none;
}
@media (min-width: 768px) {
  .hpr-cta-one-list {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    gap: 1.5rem;
  }
  .hpr-cta-one-list .hpr-divider {
    display: block;
    width: 1px;
    background-color: #ccc;
  }
}

.hpr-desktop-navigation {
  width: 100%;
  display: none;
}
.hpr-desktop-navigation .hpr-utility-nav {
  width: 100%;
  background-color: #ebf1fd;
}
.hpr-desktop-navigation .hpr-utility-nav .utility-nav-inner {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  gap: 4.5rem;
}
@media (min-width: 768px) {
  .hpr-desktop-navigation .hpr-utility-nav .utility-nav-inner {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-desktop-navigation .hpr-utility-nav .utility-nav-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-desktop-navigation .hpr-utility-nav .utility-nav-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-desktop-navigation .hpr-utility-nav .utility-nav-inner nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3.5rem;
  width: 100%;
  max-width: 670px;
}
.hpr-desktop-navigation .hpr-utility-nav .utility-nav-inner nav a {
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #022243;
  font-family: "Seravek", sans-serif;
  font-weight: 500;
  white-space: nowrap;
}
.hpr-desktop-navigation .hpr-utility-nav .utility-nav-inner .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 4.5rem;
  flex: 1;
}
.hpr-desktop-navigation .hpr-utility-nav .utility-nav-inner .actions .hpr-button-link {
  color: #fff;
  background-color: #0073cf;
  font-family: "Seravek", sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  white-space: nowrap;
}
.hpr-desktop-navigation .hpr-utility-nav .utility-nav-inner .actions .hpr-geolocate-user .input-with-icon {
  flex-direction: row-reverse;
}
.hpr-desktop-navigation .hpr-main-nav {
  width: 100%;
  position: relative;
  background-color: #022243;
  padding: 1.5rem 0;
}
.hpr-desktop-navigation .hpr-main-nav * {
  color: #fff;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .hpr-desktop-navigation .hpr-main-nav .main-nav-inner {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-desktop-navigation .hpr-main-nav .main-nav-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-desktop-navigation .hpr-main-nav .main-nav-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner h1 {
  margin: 0 !important;
  padding: 0 !important;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner nav .nav-item-wrapper {
  font-size: 1.5rem;
  line-height: 1;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner nav .nav-item-wrapper .nav-item {
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-family: "Seravek", sans-serif;
  font-weight: 400;
  text-transform: none;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner nav .nav-item-wrapper .nav-item .nav-item-title {
  pointer-events: none;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner nav .nav-item-wrapper .nav-item .nav-item-icon {
  margin-left: 0.375rem;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner nav .nav-item-wrapper .nav-item .nav-item-icon.open {
  transform: rotate(180deg);
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner nav .nav-item-wrapper .nav-item:hover {
  background-color: transparent;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner nav .hpr-search-icon {
  cursor: pointer;
  text-decoration: none;
  display: block;
  width: 32px;
  height: 32px;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner nav .hpr-search-icon svg {
  width: 32px;
  height: 32px;
  display: none;
  pointer-events: none;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner nav .hpr-search-icon svg.hpr-show {
  display: block;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown {
  display: none;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 100%;
  width: 100vw;
  background-color: #fff;
  transition: opacity 0.3s ease-in-out;
  box-shadow: 0px 15px 20px 0px rgba(0, 60, 114, 0.1);
  z-index: 10;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown.show {
  display: block;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown.visible {
  opacity: 1;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 768px) {
  .hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner h2 {
  font-size: 2rem;
  line-height: 1.1875;
  font-family: "Seravek", sans-serif;
  font-weight: 400;
  color: #022243;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .inner-dropdown-navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 2.75rem;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .inner-dropdown-navigation > .hpr-button {
  background-color: #0073cf;
  font-family: "Seravek", sans-serif;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .inner-dropdown-navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  padding-top: 0.25rem;
  padding-bottom: 1.5rem;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 2.75rem;
  grid-row-gap: 1rem;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .inner-dropdown-navigation ul li {
  padding: 0;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .inner-dropdown-navigation ul li:nth-child(odd) {
  order: 1;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .inner-dropdown-navigation ul li:nth-child(even) {
  order: 2;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .inner-dropdown-navigation ul li a {
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  font-family: "Seravek", sans-serif;
  font-weight: 400;
  color: #0073cf;
  text-decoration: underline;
  transition: all 0.3s ease;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .inner-dropdown-navigation ul li a:hover {
  filter: brightness(1.4);
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .nav-columns {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 1rem;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .nav-columns .nav-column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .nav-columns .nav-column .nav-column-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 1.5rem;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .nav-columns .nav-column .nav-column-items a {
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  font-family: "Seravek", sans-serif;
  font-weight: 400;
  color: #0073cf;
  text-decoration: underline;
  transition: all 0.3s ease;
  text-transform: none;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .nav-columns .nav-column .nav-column-items a:hover {
  filter: brightness(1.4);
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .nav-columns .nav-column .nav-column-items a.hpr-button {
  background-color: #0073cf;
  font-family: "Seravek", sans-serif;
  text-decoration: none;
  color: #fff;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}
.hpr-desktop-navigation .hpr-main-nav .main-nav-inner .nav-dropdown .nav-dropdown-inner .nav-columns .nav-column-divider {
  width: 1px;
  background: #f0ab00;
  margin: 0 2rem;
}
@media (min-width: 1280px) {
  .hpr-desktop-navigation {
    display: block;
  }
}

.hpr-footer {
  padding: 2rem 0;
  background-color: #022243;
}
.hpr-footer * {
  color: #fff;
  font-family: "Seravek", sans-serif;
}
.hpr-footer ul {
  display: block;
  position: relative;
  top: 0;
}
.hpr-footer .footer-inner {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media (min-width: 768px) {
  .hpr-footer .footer-inner {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-footer .footer-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-footer .footer-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-footer .footer-inner .footer-logo {
  width: 100%;
  max-width: 216px;
  align-self: center;
}
.hpr-footer .footer-inner .footer-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;
  text-transform: none;
  float: none;
}
.hpr-footer .footer-inner .footer-nav .footer-nav-item {
  padding: 15px 22px;
  border: 1px solid #f0ab00;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.hpr-footer .footer-inner .footer-nav .footer-nav-item .nav-item-title {
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  cursor: pointer;
  font-family: "Seravek", sans-serif;
  text-transform: none;
}
.hpr-footer .footer-inner .footer-nav .footer-nav-item .nav-item-title .hpr-expand-icon {
  display: block;
  pointer-events: none;
  width: 30px;
  height: 30px;
}
.hpr-footer .footer-inner .footer-nav .footer-nav-item .nav-item-title .hpr-expand-icon .hpr-icon {
  width: 30px;
  height: 30px;
}
.hpr-footer .footer-inner .footer-nav .footer-nav-item.footer-item-expanded .nav-item-list {
  display: flex;
}
.hpr-footer .footer-inner .footer-nav .footer-nav-item .nav-item-list {
  display: none;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  list-style: none;
}
.hpr-footer .footer-inner .footer-nav .footer-nav-item .nav-item-list li a {
  font-size: 1rem;
  line-height: 1.875;
  font-weight: 300;
  width: 100%;
  display: block;
  padding: 0;
}
.hpr-footer .footer-inner .footer-nav .footer-nav-item .nav-item-list .hpr-separator {
  margin: 0;
  background-color: #f0ab00;
  opacity: 0.5;
}
.hpr-footer .footer-inner .footer-connect {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.hpr-footer .footer-inner .footer-connect h3 {
  font-size: 1.25rem;
  line-height: 1.3;
  color: #fff;
  font-weight: 700;
  margin: 0;
}
.hpr-footer .footer-inner .footer-connect .hpr-footer-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
}
.hpr-footer .footer-inner .footer-connect .hpr-footer-contact a {
  font-size: 0.875rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
.hpr-footer .footer-inner .footer-connect .footer-social {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.hpr-footer .footer-inner .footer-connect .footer-social a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  border: 2px solid #177fb4;
  border-radius: 50%;
  transition: border 0.3s ease;
}
.hpr-footer .footer-inner .footer-connect .footer-social a:hover {
  border: 2px solid #fff;
}
.hpr-footer .footer-inner .footer-connect .footer-social a .hpr-icon {
  height: 55%;
  width: 55%;
}
.hpr-footer .footer-inner .footer-connect .footer-apps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.hpr-footer .footer-inner .footer-connect .footer-apps p {
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 400;
}
.hpr-footer .footer-inner .footer-connect .footer-apps .footer-apps-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.hpr-footer .footer-inner .footer-connect .footer-apps .footer-apps-links a {
  display: block;
  height: 38px;
}
.hpr-footer .footer-inner .footer-connect .footer-apps .footer-apps-links a img {
  height: 100%;
  object-fit: contain;
}
.hpr-footer .footer-inner .footer-legal {
  width: 100%;
  border-top: 1px solid #fff;
}
.hpr-footer .footer-inner .footer-legal .footer-legal-links {
  margin: 1.5rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
.hpr-footer .footer-inner .footer-legal .footer-legal-links a {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
}
.hpr-footer .footer-inner .footer-legal .copyright {
  display: flex;
  flex-direction: column;
  opacity: 0.6;
}
.hpr-footer .footer-inner .footer-legal .copyright p {
  font-size: 0.875rem;
  line-height: 1.8;
  font-weight: 400;
}
@media (min-width: 768px) {
  .hpr-footer {
    padding: 3.5rem 0 3rem;
  }
  .hpr-footer * {
    font-family: "Seravek", sans-serif;
  }
  .hpr-footer .footer-inner {
    gap: 2.5rem;
  }
  .hpr-footer .footer-inner .footer-logo {
    max-width: 254px;
    align-self: flex-start;
  }
  .hpr-footer .footer-inner .footer-nav {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2.875rem;
  }
  .hpr-footer .footer-inner .footer-nav .footer-nav-item {
    border: none;
    padding: 0;
    gap: 21.84px;
  }
  .hpr-footer .footer-inner .footer-nav .footer-nav-item .nav-item-title {
    font-size: 1.25rem;
    line-height: 1.3;
    font-weight: 700;
    width: fit-content;
    pointer-events: none;
    text-align: left;
  }
  .hpr-footer .footer-inner .footer-nav .footer-nav-item .nav-item-title .hpr-expand-icon {
    display: none;
  }
  .hpr-footer .footer-inner .footer-nav .footer-nav-item .nav-item-list {
    display: flex;
    gap: 1rem;
  }
  .hpr-footer .footer-inner .footer-nav .footer-nav-item .nav-item-list li a {
    font-size: 0.875rem;
    line-height: 1.1428571429;
    font-weight: 200;
  }
  .hpr-footer .footer-inner .footer-nav .footer-nav-item .nav-item-list .hpr-separator {
    display: none;
  }
  .hpr-footer .footer-inner .footer-connect {
    gap: 1.125rem;
  }
  .hpr-footer .footer-inner .footer-connect h3 {
    font-size: 1.25rem;
    line-height: 1.3;
    font-weight: 700;
    width: fit-content;
    pointer-events: none;
    text-align: left;
  }
  .hpr-footer .footer-inner .footer-connect .hpr-footer-contact a {
    font-weight: 200;
  }
  .hpr-footer .footer-inner .footer-connect .footer-social {
    margin-top: 0.5rem;
    gap: 0.625rem;
  }
  .hpr-footer .footer-inner .footer-connect .footer-social a {
    width: 35px;
    height: 35px;
    border-width: 1px;
  }
  .hpr-footer .footer-inner .footer-connect .footer-social a:hover {
    border-width: 1px;
  }
  .hpr-footer .footer-inner .footer-connect .footer-apps {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2.25rem;
  }
  .hpr-footer .footer-inner .footer-connect .footer-apps p {
    font-size: 1rem;
    line-height: 1.3;
    font-weight: 300;
  }
  .hpr-footer .footer-inner .footer-legal {
    border-top: 1px solid rgba(23, 127, 180, 0.5);
  }
  .hpr-footer .footer-inner .footer-legal .footer-legal-links {
    margin: 2rem 0 1.25rem;
    gap: 2rem;
  }
  .hpr-footer .footer-inner .footer-legal .footer-legal-links a {
    font-size: 0.875rem;
    line-height: 1.1428571429;
    font-weight: 700;
  }
  .hpr-footer .footer-inner .footer-legal .copyright {
    flex-direction: row;
    gap: 4px;
  }
  .hpr-footer .footer-inner .footer-legal .copyright p {
    font-size: 0.75rem;
    line-height: 1.1666666667;
    font-weight: 400;
  }
}

.hpr-global-navigation {
  top: 0;
  width: 100%;
  position: sticky;
  height: 52px;
  z-index: 1000;
}
.hpr-global-navigation .hpr-search-bar-container {
  background: #fff;
}
@media (min-width: 1280px) {
  .hpr-global-navigation {
    height: 139px;
  }
}

.hpr-image-top-cta-list {
  display: flex;
  flex-direction: column;
  gap: 1.65rem;
}
.hpr-image-top-cta-list h2 {
  margin-bottom: 0.375rem;
}
.hpr-image-top-cta-list .ctas {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
}
@media (min-width: 768px) {
  .hpr-image-top-cta-list {
    gap: 1.25rem;
  }
  .hpr-image-top-cta-list h2 {
    margin-bottom: 0;
  }
  .hpr-image-top-cta-list .ctas {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1.5rem;
  }
}

.hpr-mobile-navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  z-index: 10;
}
.hpr-mobile-navigation ul {
  position: relative;
  top: 0;
}
.hpr-mobile-navigation .nav-header {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #022243;
}
@media (min-width: 768px) {
  .hpr-mobile-navigation .nav-header {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-mobile-navigation .nav-header {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-mobile-navigation .nav-header {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-mobile-navigation .nav-header .hpr-logo {
  max-width: 162px;
}
.hpr-mobile-navigation .nav-header .hpr-logo h1 {
  margin: 0 !important;
  padding: 0 !important;
}
.hpr-mobile-navigation .nav-header .header-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.hpr-mobile-navigation .nav-header .header-controls .hpr-search-icon {
  width: 30px;
  height: 30px;
}
.hpr-mobile-navigation .nav-header .header-controls .hpr-search-icon svg {
  width: 30px;
  height: 30px;
  display: none;
  pointer-events: none;
}
.hpr-mobile-navigation .nav-header .header-controls .hpr-search-icon svg.hpr-show {
  display: block;
}
.hpr-mobile-navigation .mobile-nav-menu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 52px);
  overflow-y: scroll;
  background-color: #f4f4f4;
  opacity: 0;
  padding-top: 50px;
  width: 100%;
  position: absolute;
  overflow-x: hidden;
  top: 100%;
  z-index: 8;
}
.hpr-mobile-navigation .mobile-nav-menu.app-banner-active {
  height: calc(100vh - 52px - 88px);
}
.hpr-mobile-navigation .mobile-nav-menu.show {
  display: flex;
}
.hpr-mobile-navigation .mobile-nav-menu.visible {
  opacity: 1;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
}
@media (min-width: 768px) {
  .hpr-mobile-navigation .mobile-nav-menu .nav-container {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-mobile-navigation .mobile-nav-menu .nav-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-mobile-navigation .mobile-nav-menu .nav-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  z-index: 1;
  top: 0;
  position: static;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav * {
  color: #177fb4;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-item {
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  color: #0073cf;
  font-family: "Seravek", sans-serif;
  font-weight: 400;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-item .nav-item-icon-mobile {
  margin-left: 0.375rem;
  width: 14px;
  pointer-events: none;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-item:hover {
  background-color: transparent;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-item-title {
  font-size: 1.25rem;
  line-height: 1;
  color: #0073cf;
  font-family: "Seravek", sans-serif;
  font-weight: 400;
  padding: 0;
  text-decoration: underline;
  text-transform: none;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper a.nav-item-title {
  width: 100%;
  text-align: left;
  display: block;
  text-transform: none;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider {
  display: flex;
  background: #fff;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  padding: 1.5rem;
  flex-direction: column;
  gap: 1rem;
  opacity: 0;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider.show {
  left: 0;
  opacity: 1;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider .nav-slider-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  gap: 5px;
  cursor: pointer;
  color: #0073cf;
  font-family: "Seravek", sans-serif;
  font-weight: 400;
  text-decoration: none;
  transition: filter 0.3s ease;
  line-height: 1rem;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider .nav-slider-back:hover, .hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider .nav-slider-back:active {
  filter: brightness(1.1);
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider .nav-slider-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider .nav-slider-content h3 {
  color: #022243;
  font-family: "Seravek", sans-serif;
  font-weight: 400;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider .nav-slider-content .hpr-button {
  background-color: #0073cf;
  font-family: "Seravek", sans-serif;
  color: #fff;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 0.5rem;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider .nav-slider-content ul {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  gap: 1.5rem;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider .nav-slider-content ul li {
  padding: 0;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider .nav-slider-content ul li a {
  line-height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-main-nav .nav-item-wrapper .nav-slider .nav-slider-content .hpr-separator {
  display: block;
  margin: 1rem 0;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .hpr-separator {
  display: block;
  background-color: #f0ab00;
  margin: 1.5rem 0;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-utility-nav {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  z-index: 0;
  top: initial;
  bottom: initial;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-utility-nav .hpr-text-link {
  font-size: 1.25rem;
  line-height: 1;
  color: #0073cf;
  font-family: "Seravek", sans-serif;
  font-weight: 400;
  text-decoration: underline;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-utility-nav .my-chart-mobile {
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: #265698;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  max-width: 184px;
  text-align: center;
  text-decoration: none;
  margin-top: 0.5rem;
  transition: filter 0.3s ease;
}
.hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-utility-nav .my-chart-mobile:hover, .hpr-mobile-navigation .mobile-nav-menu .nav-container .mobile-utility-nav .my-chart-mobile:active {
  filter: brightness(1.1);
}
.hpr-mobile-navigation .mobile-nav-menu .geolocation {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .hpr-mobile-navigation .mobile-nav-menu .geolocation {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-mobile-navigation .mobile-nav-menu .geolocation {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-mobile-navigation .mobile-nav-menu .geolocation {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 450px) {
  .hpr-mobile-navigation .mobile-nav-menu {
    max-width: 390px;
  }
}
@media (min-width: 1024px) {
  .hpr-mobile-navigation .mobile-nav-menu.app-banner-active {
    height: calc(100vh - 52px);
  }
}
@media (min-width: 1280px) {
  .hpr-mobile-navigation {
    display: none;
  }
}

.hpr-modal {
  background-color: rgba(24, 25, 34, 0.5);
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  outline: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  z-index: 10;
}
.hpr-modal.visible {
  display: block;
  z-index: 999999;
}
.hpr-modal.open {
  opacity: 1;
}
.hpr-modal .modal-container {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
  max-height: 100vh;
}
.hpr-modal .modal-inner-container {
  overflow: auto;
  border-radius: 6px;
  max-height: 80vh;
}
.hpr-modal .modal-body {
  flex-grow: 1;
  overflow-y: auto;
}
.hpr-modal .modal-inner {
  overflow: auto;
  position: relative;
  border-radius: 6px;
}
.hpr-modal .modal-inner .modal-content {
  position: relative;
  background: none;
}
.hpr-modal .modal-inner .modal-content .modal-close-btn {
  align-items: center;
  background-color: none;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: 0;
  position: absolute;
  right: 1%;
  top: 2%;
  width: 1.75rem;
  height: 1.75rem;
}
.hpr-modal .modal-inner .modal-content .modal-close-btn:hover, .hpr-modal .modal-inner .modal-content .modal-close-btn:focus {
  background-color: #777;
}
.hpr-modal .modal-inner .modal-content .modal-close-btn.text-close {
  color: #777;
  height: auto;
  transform: none;
  width: auto;
}
.hpr-modal .modal-inner .modal-content .modal-close-btn.text-close:hover, .hpr-modal .modal-inner .modal-content .modal-close-btn.text-close:focus {
  text-decoration: underline;
}
.hpr-modal .modal-inner .modal-content .modal-close-btn .hpr-icon {
  height: 0.875rem;
  stroke: #000;
  width: 0.875rem;
}
@media (min-width: 768px) {
  .hpr-modal.visible {
    align-items: center;
    background-color: rgba(24, 25, 34, 0.5);
    display: flex;
  }
  .hpr-modal .modal-inner-container {
    background-color: #fff;
    max-height: 500px;
    max-width: 1000px;
  }
  .hpr-modal .modal-body {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
}

.hpr-patient-cards-list {
  color: #000;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hpr-patient-cards-list .patient-card:not(:last-of-type) {
  padding-bottom: 29px;
  border-bottom: 1px solid #ccc;
}
@media (min-width: 768px) {
  .hpr-patient-cards-list {
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .hpr-patient-cards-list .patient-card {
    flex: 0 1 50%;
  }
  .hpr-patient-cards-list .patient-card:not(:last-of-type) {
    padding-bottom: 0;
    border-bottom: none;
  }
  .hpr-patient-cards-list .patient-card:nth-of-type(odd) {
    padding-right: 12px;
    border-right: 1px solid #ccc;
  }
  .hpr-patient-cards-list .patient-card:nth-of-type(even) {
    padding-left: 12px;
  }
  .hpr-patient-cards-list .patient-card:nth-last-of-type(2), .hpr-patient-cards-list .patient-card:last-of-type {
    margin-bottom: 0;
  }
}

.hpr-primary-cards-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
}
@media (min-width: 768px) {
  .hpr-primary-cards-list {
    align-items: start;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  .hpr-primary-cards-list .hpr-image-card {
    height: 100%;
  }
  .hpr-primary-cards-list .hpr-image-card .card-container {
    flex: 1;
  }
  .hpr-primary-cards-list .hpr-image-card .hpr-button {
    display: block;
    width: 100%;
  }
  .hpr-primary-cards-list .hpr-image-card .image-card-info .hpr-button {
    display: none;
  }
  .hpr-primary-cards-list .hpr-image-card .image-wrapper {
    flex: 0 1 50%;
    width: auto;
  }
}
@media (min-width: 1100px) {
  .hpr-primary-cards-list .hpr-image-card .image-card-info .hpr-button {
    display: block;
    width: auto;
  }
  .hpr-primary-cards-list .hpr-image-card .hpr-button {
    display: none;
  }
}

.hpr-services-contact-numbers {
  background-color: #eee;
  margin-bottom: 60px;
  padding-top: 30px;
}
.hpr-services-contact-numbers .services-contact-numbers-inner {
  display: flex;
  flex-direction: column;
}
.hpr-services-contact-numbers .hpr-section-title {
  padding: 0 16px;
}
.hpr-services-contact-numbers .hpr-services-list:last-of-type {
  margin-bottom: 0;
}
.hpr-services-contact-numbers + .hpr-small-cards-list {
  margin-top: -60px;
}
@media (min-width: 768px) {
  .hpr-services-contact-numbers {
    padding: 60px 0;
  }
  .hpr-services-contact-numbers + .hpr-small-cards-list {
    margin-top: -120px;
  }
  .hpr-services-contact-numbers .services-contact-numbers-inner {
    margin-right: auto;
    margin-left: auto;
    max-width: 1280px;
    padding-right: 16px;
    padding-left: 16px;
    width: 100%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .hpr-services-contact-numbers .services-contact-numbers-inner {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .hpr-services-contact-numbers .services-contact-numbers-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 768px) and (min-width: 1440px) {
  .hpr-services-contact-numbers .services-contact-numbers-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}

.hpr-small-cards-list {
  padding: 60px 0;
}
.hpr-small-cards-list .hpr-small-cards-list-inner {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
}
@media (min-width: 768px) {
  .hpr-small-cards-list .hpr-small-cards-list-inner {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-small-cards-list .hpr-small-cards-list-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-small-cards-list .hpr-small-cards-list-inner {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-small-cards-list .hpr-image-card {
  border-bottom: 1px solid #ccc;
  padding-bottom: 31px;
  margin-bottom: 31px;
}
.hpr-small-cards-list .hpr-image-card:last-of-type {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.hpr-small-cards-list .hpr-image-card .card-container {
  background-color: #fff;
}
@media (min-width: 768px) {
  .hpr-small-cards-list {
    background-color: #eee;
  }
  .hpr-small-cards-list .hpr-small-cards-list-inner {
    align-items: start;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
  }
  .hpr-small-cards-list .hpr-image-card {
    height: 100%;
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .hpr-small-cards-list .hpr-image-card .card-container {
    background-color: #fff;
    flex: 1;
  }
  .hpr-small-cards-list .hpr-image-card .image-wrapper {
    flex: 0 1 50%;
    min-width: 50%;
    max-width: 50%;
  }
  .hpr-small-cards-list .hpr-image-card .image-card-info .hpr-button {
    display: none;
  }
  .hpr-small-cards-list .hpr-image-card .hpr-button {
    display: block;
  }
}
@media (min-width: 1100px) {
  .hpr-small-cards-list .hpr-image-card .image-wrapper {
    flex: 0 1 164px;
    min-width: 164px;
    max-width: 164px;
  }
  .hpr-small-cards-list .hpr-image-card .image-card-info .hpr-button {
    display: block;
    width: auto;
  }
  .hpr-small-cards-list .hpr-image-card .hpr-button {
    display: none;
  }
}

.hpr-services-and-providers-card {
  background-color: #eee;
}
.hpr-services-and-providers-card .services-card-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
}
@media (min-width: 768px) {
  .hpr-services-and-providers-card .services-card-container {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-services-and-providers-card .services-card-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-services-and-providers-card .services-card-container {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-services-and-providers-card .card-inner {
  padding-bottom: 44px;
  padding-top: 28px;
}
.hpr-services-and-providers-card .card-left {
  border-bottom: 1px solid #ccc;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.hpr-services-and-providers-card .services-and-providers-main-heading,
.hpr-services-and-providers-card .services-and-providers-secondary-heading {
  font-size: 1.125rem;
  line-height: 1.1666666667;
  margin-bottom: 18px;
}
.hpr-services-and-providers-card .services-and-providers-list {
  margin-bottom: 20px;
}
.hpr-services-and-providers-card .services-and-providers-list li {
  margin-bottom: 8px;
}
.hpr-services-and-providers-card .services-and-providers-list li a {
  font-size: 1rem;
  line-height: 1.125;
  color: #177fb4;
  text-decoration: underline;
}
.hpr-services-and-providers-card .hpr-image-card {
  margin-bottom: 30px;
}
.hpr-services-and-providers-card .hpr-button {
  width: 100%;
}
.hpr-services-and-providers-card .hpr-text-body {
  margin-bottom: 20px;
}
.hpr-services-and-providers-card .buttons-container .hpr-button {
  margin-bottom: 15px;
}
.hpr-services-and-providers-card .buttons-container .hpr-button:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .hpr-services-and-providers-card {
    background-color: #fff;
    margin-bottom: 25px;
    margin-top: 75px;
  }
  .hpr-services-and-providers-card .card-container {
    padding: 0;
  }
  .hpr-services-and-providers-card .card-inner {
    align-items: stretch;
    background-color: #eee;
    border-radius: 6px;
    display: flex;
    padding: 32px;
  }
  .hpr-services-and-providers-card .card-left {
    padding: 0 36px 0 0;
    margin: 0;
    border-bottom: none;
    min-width: 50%;
    border-right: 1px solid #ccc;
    flex: 0 1 50%;
  }
  .hpr-services-and-providers-card .services-and-providers-list {
    columns: 2;
    column-gap: 24px;
  }
  .hpr-services-and-providers-card .card-right {
    flex: 0 1 50%;
    min-width: 50%;
    padding-left: 36px;
  }
  .hpr-services-and-providers-card .hpr-image-card .card-inner {
    padding: 0;
  }
  .hpr-services-and-providers-card .hpr-image-card .image-card-info .hpr-button {
    display: none;
  }
  .hpr-services-and-providers-card .hpr-image-card .hpr-button {
    display: block;
  }
  .hpr-services-and-providers-card .buttons-container {
    display: flex;
    gap: 22px;
  }
  .hpr-services-and-providers-card .buttons-container .hpr-button {
    flex: 0 1 50%;
    margin: 0;
    min-width: 0;
  }
  .hpr-services-and-providers-card .hpr-button {
    width: auto;
  }
}
@media (min-width: 1100px) {
  .hpr-services-and-providers-card .hpr-image-card .image-card-info .hpr-button {
    display: block;
  }
  .hpr-services-and-providers-card .hpr-image-card .hpr-button {
    display: none;
  }
}

.hpr-zipcode-popover {
  position: relative;
}
.hpr-zipcode-popover .popover-trigger {
  display: flex;
  cursor: pointer;
}
.hpr-zipcode-popover .popover-trigger .hpr-popover-trigger-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  margin: 0;
  cursor: pointer;
}
.hpr-zipcode-popover .popover-trigger .hpr-popover-trigger-btn .icon-location {
  fill: #177fb4;
}
.hpr-zipcode-popover .popover-trigger .hpr-popover-trigger-btn .zip-value {
  font-size: 1rem;
  line-height: 1.375;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #022243;
}
.hpr-zipcode-popover .popover-content {
  background: #fff;
  position: absolute;
  z-index: 50;
  width: 258px;
  bottom: 225%;
  left: 0;
  border-radius: 6px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 1rem;
  border: 1px solid #fff;
  height: fit-content;
  display: none;
  flex-direction: column;
  gap: 10px;
}
.hpr-zipcode-popover .popover-content.show {
  display: flex;
}
.hpr-zipcode-popover .popover-content::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 2rem;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  z-index: 49;
}
.hpr-zipcode-popover .popover-content .hpr-popover-close-btn {
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  cursor: pointer;
  margin: 0;
}
.hpr-zipcode-popover .popover-content .hpr-geolocate-user {
  width: 100%;
}
.hpr-zipcode-popover .popover-content .hpr-geolocate-user .input-with-icon {
  width: 100%;
}
.hpr-zipcode-popover .popover-content .hpr-geolocate-user .input-with-icon .zip-input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 12px 14px;
  border-radius: 6px;
  font-family: "Seravek", sans-serif;
  font-weight: 400;
}
.hpr-zipcode-popover .popover-content p {
  font-size: 14px;
}
.hpr-zipcode-popover .popover-content .popover-update-btn {
  min-width: 0;
}
@media (min-width: 1280px) {
  .hpr-zipcode-popover .popover-content {
    top: 225%;
    right: -2rem;
    left: initial;
  }
  .hpr-zipcode-popover .popover-content::before {
    content: "";
    position: absolute;
    top: -20px;
    right: 2rem;
    left: initial;
    bottom: initial;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    z-index: 49;
  }
}

/* Pages (e.g. Search Page, Article Page) */
/* Templates (e.g. Home, Full Width, Two Column) */
.layout-campaign main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .layout-campaign main {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

.layout-home {
  background-color: #777;
}

.hpr-layout-full-width {
  background-color: #fff;
  margin: 0;
  max-width: none;
}
.hpr-layout-full-width main,
.hpr-layout-full-width .hpr-containerized-element {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .hpr-layout-full-width main,
  .hpr-layout-full-width .hpr-containerized-element {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (min-width: 1280px) {
  .hpr-layout-full-width main,
  .hpr-layout-full-width .hpr-containerized-element {
    padding-right: 88px;
    padding-left: 88px;
  }
}
@media (min-width: 1440px) {
  .hpr-layout-full-width main,
  .hpr-layout-full-width .hpr-containerized-element {
    padding-right: 88px;
    padding-left: 88px;
  }
}
.hpr-layout-full-width .hpr-gray-bg {
  background-color: #eee;
}
@media (min-width: 768px) {
  .hpr-layout-full-width main,
  .hpr-layout-full-width .hpr-containerized-element {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

.hpr-layout-full-width-compact {
  background-color: #777;
}
.hpr-layout-full-width-compact main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .hpr-layout-full-width-compact main {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

.layout-two-column {
  background-color: #777;
}
@media (min-width: 1280px) {
  .layout-two-column .layout-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    justify-content: space-between;
  }
  .layout-two-column .column-main {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .layout-two-column .column-sidebar {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 12px;
  }
}

/* Print (e.g. Provider Print, Location Print) */
@media print {
  body,
  html {
    width: 1100px;
  }
  * {
    transition: none !important;
  }
  header,
  footer,
  aside,
  nav,
  form,
  iframe {
    display: none !important;
  }
  @page {
    size: 330mm 427mm;
    margin: 14mm;
  }
}
.hpr-cta-one-list {
	width: 100%;
	max-width: 980px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2.5rem;

	.hpr-divider {
		display: none;
	}

	@include breakpoint(md) {
		flex-direction: row;
		align-items: stretch;
		justify-content: space-between;
		gap: 1.5rem;

		.hpr-divider {
			display: block;
			width: 1px;
			background-color: $color-neutral-light;
		}
	}
}

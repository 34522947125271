.two-column {
	@include breakpoint(md) {
		columns: 2;
	}
}
.three-column {
	@include breakpoint(md) {
		columns: 2;
	}

	@include breakpoint(lg) {
		columns: 3;
	}
}
.four-column {
	@include breakpoint(md) {
		columns: 2;
	}

	@include breakpoint(lg) {
		columns: 3;
	}

	@include breakpoint(xl) {
		columns: 4;
	}
}

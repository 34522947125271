.hpr-hero-cta {
	width: 100%;
	background-color: rgba(2, 34, 67, 0.35);

	.hero-cta-container {
		@include make-container();
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 8px;
		padding-top: 0.875rem;
		padding-bottom: 0.875rem;

		h5 {
			@include text(26);
			color: $color-white;
			font-family: $font-family-quaternary;
			font-weight: 300;
			margin: 0;
		}

		a {
			border-radius: 6px;
			width: 100%;
			max-width: 330px;
		}
	}

	@include breakpoint(md) {
		.hero-cta-container {
			flex-direction: row;
			justify-content: space-between;
			padding-top: 0.875rem;
			padding-bottom: 0.875rem;

			h5 {
				@include text(32, 50);
			}

			a {
				width: fit-content;
			}
		}
	}

	@include breakpoint(850px) {
		.hero-cta-container {
			h5 {
				@include text(42, 60);
			}
		}
	}
}

.hpr-small-cards-list {
	padding: 60px 0;

	.hpr-small-cards-list-inner {
		@include make-container();
	}

	.hpr-image-card {
		border-bottom: 1px solid $color-neutral-light;
		padding-bottom: 31px;
		margin-bottom: 31px;

		&:last-of-type {
			border: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		.card-container {
			background-color: $color-white;
		}
	}

	@include breakpoint(md) {
		background-color: $color-gray-light;

		.hpr-small-cards-list-inner {
			align-items: start;
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			gap: 24px;
		}

		.hpr-image-card {
			height: 100%;
			border: none;
			margin-bottom: 0;
			padding-bottom: 0;

			.card-container {
				background-color: $color-white;
				flex: 1;
			}

			.image-wrapper {
				flex: 0 1 50%;
				min-width: 50%;
				max-width: 50%;
			}

			.image-card-info {
				.hpr-button {
					display: none;
				}
			}

			.hpr-button {
				display: block;
			}
		}
	}

	@include breakpoint(1100px) {
		.hpr-image-card {
			.image-wrapper {
				flex: 0 1 164px;
				min-width: 164px;
				max-width: 164px;
			}

			.image-card-info {
				.hpr-button {
					display: block;
					width: auto;
				}
			}

			.hpr-button {
				display: none;
			}
		}
	}
}
